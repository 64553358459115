import { Outlet  } from "react-router-dom";
import {Logo, LogoWithText} from './ui-components';
import Session from './Session';
import NavMenu from "./NavMenu";
import './Layout.css';
import React, {useEffect} from "react";
import {Card, useTheme, View} from "@aws-amplify/ui-react";
import {Helmet} from "react-helmet";

function Layout() {
    const { tokens } = useTheme();

    useEffect(() => {
        const banner = document.getElementsByClassName('Banner')[0];
        if (banner) {
            banner.style.position = 'relative';
        }
    } );

  return (
    <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Helmet>
      <div className="MainContainer">
        <div className="LeftBar column" style={{ backgroundColor: tokens.colors.brand.primary[80] }}>
          <View className="print-logo">
            <LogoWithText color="brand" margin="xl xs 0 xxxs"/>
          </View>
          <View backgroundColor="font.secondary">
            <Card height="72px" backgroundColor="brand.primary.80" borderRadius="0 0 72px 0"></Card>
          </View>
          <Card backgroundColor="font.secondary" borderRadius="80px 0 0 48px">
            <NavMenu />
          </Card>
          <View backgroundColor="font.secondary">
            <Card height="112px" backgroundColor="brand.primary.80" borderRadius="0 112px 0 0"></Card>
          </View>
        </div>
        <div className="Main" style={{ backgroundColor: tokens.colors.font.secondary }}>
          <div className="Banner no-print">
            <View>
                <Logo className="TopBar" color="brand"></Logo>
            </View>
            <View>
                <div className="TopBar row"><NavMenu/></div>
            </View>
            <Session/>
          </div>
          <Outlet/>
        </div>
      </div>
    </>
  );
}

export default Layout;
