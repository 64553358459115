import React from "react";
import {post} from "aws-amplify/api";
import {Button} from "@aws-amplify/ui-react";

class VerifyButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            presenting: false,
            submitted: false
        };
        this.handleClick = this.handleClick.bind(this);
        console.debug("Stripe is available", props);
    }

    async componentDidMount() {
        this.setState({ stripe: await this.props.stripePromise });
    }

    async handleClick(event) {
        // Block native event handling.
        event.preventDefault();

        const { stripe } = this.state;

        if (!stripe) {
            // Stripe.js hasn't loaded yet. Make sure to disable
            // the button until Stripe.js has loaded.
            return;
        }
        if (!this.props.accountId) {
            // Either the user's account hasn't loaded yet,
            // or the user doesn't have an account.
            // We don't want to start expensive verification
            // if we can't associate the verification with
            // the user's account.
            return;
        }
        this.setState({ loading: true }); // Start loading

        // Call the backend to create a verification session.
        try {
            const postOperation = await post({
                apiName: 'TodoTikStripe',
                path: '/verification-session',
                options: {
                    body: {
                        metadata: {
                            accountId: this.props.accountId,
                            email: this.props.email
                        }
                    }
                }
            });
            const response = await postOperation.response;
            const session = await response.body.json();

            // Stop loading, start presenting
            this.setState({ loading: false, presenting: true });

            // Show the verification modal.
            const { error } = await stripe.verifyIdentity(session.client_secret);

            this.setState({ presenting: false });

            if (error) {
                console.log('[error]', error);
            } else {
                console.log('Verification submitted!');
                this.setState({ submitted: true });
            }
        }
        catch (error) {
            console.log('Caught error', error);
            this.setState({ loading: false, presenting: false, submitted: false });
        }
    };
    render() {
        const { stripe, loading, presenting, submitted } = this.state;

        if (submitted) {
            return (
                <Button className="no-print" role="link" variation="primary" disabled={true}>
                    Submitted
                </Button>
            );
        }
        return (
            <Button isLoading={loading} disabled={ !this.props.accountId || loading || presenting || submitted || !stripe}
                    onClick={this.handleClick}
                    variation="primary"
                    className="no-print"
                    loadingText={"Ready\u00a0Your\u00a0ID"}
            >{presenting? <>Ready&nbsp;for&nbsp;camera!</> : <>Show&nbsp;Your&nbsp;ID</>}</Button>
        );
    }
}

export default VerifyButton;