import '@aws-amplify/ui-react/styles.css';

import React, { useState } from 'react';

import {Link, useNavigate} from 'react-router-dom';

import { EmailForm, VerificationForm } from './ui-components';

import { Card, Flex, Text } from '@aws-amplify/ui-react';
import { updateUserAttribute, confirmUserAttribute } from 'aws-amplify/auth';
import {useSession} from "./SessionContext";

const Email = () => {

    const { userAttributes, setUserAttributes } = useSession();

    const [newEmail, setNewEmail] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    async function handleUpdateClick(fields) {
        const updatedEmail = fields['email'].trim();
        if (updatedEmail === userAttributes.email) {
            console.log('Email is not changed')
            navigate('/profile/');
            return;
        }
        else {
            // getUserAttributeVerificationCode();
        }
        setNewEmail(updatedEmail);

        try {
            const output = await updateUserAttribute({
                userAttribute: {
                    attributeKey: 'email',
                    value: updatedEmail
                }
            });
            const { nextStep } = output;
            switch (nextStep.updateAttributeStep) {
                case 'CONFIRM_ATTRIBUTE_WITH_CODE':
                    setErrorMessage('');
                    const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    console.log(
                        `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
                    );
                    // Collect the confirmation code from the user and pass to confirmUserAttribute.
                    setCodeSent(true);
                    break;
                case 'DONE':
                    // Attribute was successfully updated.
                    setUserAttributes(
                        {
                        ...userAttributes,
                            email: updatedEmail
                        }
                    );
                    navigate('/profile/');
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
        return {'email': updatedEmail};
    }

    const handleCancel = () => {
        console.log('Canceled email change')
        navigate('/profile/');
    }

    const handleCancelVerify = () => {
        console.log('Canceled email verification')
        setCodeSent(false);
    }

    async function handleVerifyClick(fields) {
        const confirmationCode = fields['code'].trim();

        try {
            console.log('Confirming email: ', confirmationCode);
            await confirmUserAttribute({
                userAttributeKey: 'email',
                confirmationCode: confirmationCode
            });
            console.log('Email is successfully changed')
            navigate('/profile/');
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        }
    }

    function renderUpdateForm() {
        return (
            <EmailForm minWidth="90%"
                initialData={{
                    email: newEmail? newEmail : (userAttributes? userAttributes.email : '')
                }}
                onCancel={handleCancel}
                onSubmit={handleUpdateClick} />
        );
    }

    function renderConfirmationForm() {
        return (
            <Flex direction="column">
            <VerificationForm
                onChange={() => {
                    setErrorMessage('');
                }}
                onCancel={handleCancelVerify}
                onSubmit={handleVerifyClick}
            />
            <Text variation="error" color="font.error">{errorMessage? errorMessage : ''}</Text>
            </Flex>
        );
    }

    return (
      <Card backgroundColor="brand.primary.80" margin="medium" className="ChangeEmail" textAlign="left" borderRadius="40px">
        <Flex direction="column" textAlign="center" gap="xs">
          <Text className="no-print"><Link className="App-link" to="/profile/">&lsaquo; Profile</Link></Text>
          <Text as="h1" fontSize="xl" fontWeight="bold" color="font.secondary">Change email</Text>
        </Flex>
        <Flex maxWidth="100%" justifyContent="space-around">
          {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
        </Flex>
      </Card>
    );
}

export default Email;