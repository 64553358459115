import { Link } from 'react-router-dom';

import './legal.css';
import { Text, View } from "@aws-amplify/ui-react";

const Privacy = () => {

  return (
    <View className="legal">
      <Text as="h1">Privacy Policy</Text>

      <Text as="p">Last Updated: 1st of January, 2024</Text>
      <Text>
        This Privacy Policy is subject to the terms outlined in
        our&nbsp;<Link to="/terms/">Terms of Service</Link>.
      </Text>

      <Text as="p">
        TodoTik, S.L. ("TodoTik", "we", "us", or "our") is committed to safeguarding
        the privacy and personal information of our customers and website visitors.
        This Privacy Policy outlines our practices regarding the collection, use,
        and protection of your personal data.
      </Text>

      <Text as="h2">2. Scope and Consent</Text>

      <Text as="p">
        This policy details the handling of personal data by TodoTik during your
        interactions with our website, such as when you sign up for an account,
        purchase services, or engage with members of the community in various
        projects and discussions.
      </Text>
      <Text as="p">
        By accessing and using TodoTik's services, you acknowledge and agree to
        the terms outlined in this Privacy Policy and provide your consent for
        us to process your data accordingly.
      </Text>

      <Text as="h2">3. The Data We Collect About You</Text>

      <Text as="p">
        Personal data, or personal information, means any information about an
        individual from which that person can be identified.
        It does not include data where the identity has been removed (anonymous data).
        We may collect, use, store and transfer different kinds of personal data
        about you which we have grouped together as follows:
      </Text>

      <dl>
        <dt>Personal Identity Data</dt>
        <dd>Includes first name, last name, personal identifier, date of birth, gender,
          marital status, and title.
        </dd>
        <dt>Security Data</dt>
        <dd>Data used to secure the usage of our services and premises,
          including your login credentials, session context, and security logs.
        </dd>
        <dt>Contact Data</dt>
        <dd>Includes billing address, delivery address, email address, telephone number,
          your organization and role information, language, and time zone.
        </dd>
        <dt>Location Data</dt>
        <dd>
          Your location is utilized to display nearby activities and topics,
          keeping you connected with your local community. This data is accessed
          only when you are using the app and have granted permission. Granting
          location access is essential for the app to function properly.
          Additionally, any interactions, documents, records, and photos
          you upload to the app will have location data associated with them
          when placed on the map.
        </dd>
        <dt>Device and Connection Data</dt>
        <dd>
          Includes internet protocol (IP) address, your device identifiers,
          browser type and version, browser extension types and versions,
          operating system and platform, and other technology on the devices
          you use to access this website.
        </dd>
        <dt>Financial Data</dt>
        <dd>Your bank account, payment card and other payment method details.
        </dd>
        <dt>Contractual and Transaction Data</dt>
        <dd>This encompasses information about transactions,
          including details of payments made by you or to you,
          and specifics of products and services you have purchased from us.
          It also includes records of your consents, opt-ins, and opt-outs.
        </dd>
        <dt>Usage Log Data</dt>
        <dd>Operational information about your use of our website, products and services.
        </dd>
        <dt>Tracking Data and Identifiers</dt>
        <dd>Analytical data collected through cookies, browser extensions or similar technologies
          related to your usage of our services, including browsing activities,
          segments, cookie identifiers, browser and device details.
        </dd>
        <dt>Profile Data</dt>
        <dd>Includes your interests, preferences, feedback and survey responses
          for operational or development purposes.
        </dd>
        <dt>Marketing Data</dt>
        <dd>
          Includes your preferences in receiving marketing from us and our third parties
          and your communication preferences for promotional purposes.
        </dd>
      </dl>

      <Text as="h2">4. How We Use Your Personal Data</Text>

      <Text as="p">
      We will only use your personal data when the law allows us to.
      Most commonly, we will use your personal data in the following circumstances:
      </Text>
      <ul>
        <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
        <li>Where it is necessary for our legitimate interests (or those of a third party), and
          your interests and fundamental rights do not override those interests.</li>
        <li>Where we need to comply with a legal obligation.</li>
      </ul>

      <Text as="h2">5. Disclosures of Your Personal Data</Text>

      <Text as="p">
        We may share your personal data with external third parties,
        such as service providers acting as processors who provide IT
        and system administration services, professional advisers
        including lawyers, auditors and insurers who provide consultancy,
        banking, legal, insurance and accounting services, and regulators
        and other authorities who require reporting of processing activities
        in certain circumstances.
      </Text>

      <Text as="h2">6. Data Security</Text>

      <Text as="p">
        We have put in place appropriate security measures to prevent
        your personal data from being accidentally lost, used or accessed
        in an unauthorized way, altered or disclosed.
        We limit access to your personal data to those employees, agents,
        contractors and other third parties who have a business need to know.
      </Text>

      <Text as="h2">7. Data Retention</Text>

      <Text as="p">
        We will only retain your personal data for as long as necessary
        to fulfill the purposes we collected it for, including for the
        purposes of satisfying any legal, accounting, or reporting requirements.
      </Text>

      <Text as="h2">8. Your Legal Rights</Text>

      <Text as="p">
        Under certain circumstances, you have rights under data protection
        laws in relation to your personal data, including the right to request
        access, correction, erasure, restriction, transfer, to object to
        processing, to portability of data and (where the lawful ground of
        processing is consent) to withdraw consent.
      </Text>

      <Text as="h2">9. Glossary</Text>

      <h3>Lawful Basis</h3>
      <dl>
        <dt>Legitimate Interest</dt><dd>means the interest of our business in conducting
        and managing our business to enable us to give you the best service/product
        and the best and most secure experience.</dd>
        <dt>Performance of Contract</dt><dd>means processing your data where it is necessary
        for the performance of a contract to which you are a party or to take steps
        at your request before entering into such a contract.</dd>
        <dt>Comply with a legal obligation</dt><dd>means processing your personal data where it
        is necessary for compliance with a legal obligation that we are subject to.</dd>
      </dl>

      <Text as="h2">10. Contact Details</Text>

      <Text as="p">
        If you have any questions about this Privacy Policy or our privacy practices,
        please contact us in the following ways:
      </Text>

      <Text as="h3">Postal address:</Text>
      <Text as="p">TodoTik, S.L.<br/>
        C/ Casa de la Paz, 1<br/>
        Blq. 2, Esc. A, PB, Pta. 01<br/>
        29680 Estepona, Málaga<br/>
        SPAIN
      </Text>

      <Text as="h3">Email address:</Text>
      <Text as="p">
        <a href="mailto:privacy@todotik.com">privacy@todotik.com</a>
      </Text>

      <Text as="h3">Website address:</Text>
      <Text as="p" marginBottom="large">
        <Link to="/">https://todotik.com/</Link>
      </Text>
    </View>
  );
}

export default Privacy;
