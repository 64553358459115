import React from 'react';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';

import {Card, Flex} from '@aws-amplify/ui-react';

import { useSession } from './SessionContext';
import {Link} from "react-router-dom";

import { Text, View } from '@aws-amplify/ui-react';

function SignOut() {
    const authState = useSession();
    const query = new URLSearchParams(window.location.search);
    // OAuth 2.0 response parameters
    const { error, error_description } = Object.fromEntries(query);

    return (
      <View className="fill-available" backgroundColor="font.secondary">
        <Flex direction="column" justifyContent="space-around" alignItems="center">
            {error ?
                <Card backgroundColor="background.error" borderRadius="xl" margin="xs xl">
                    <Text as="h2" color="font.error" margin="small medium">
                        {error}
                    </Text>
                    <Text as="p" color="font.error" margin="small medium">
                        {error_description}
                    </Text>
                </Card>
                : ''
            }
            <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.active" margin="xl medium xs medium">
              See You Soon
            </Text>
            <Card variation="elevated" backgroundColor="brand.primary.90" maxWidth="300pt" margin="xs xl"
                  padding="large" borderRadius="xl">
                {authState === 'authenticated'? (
                  <Text as="p" color="font.primary" margin="small medium">
                      Signing out...
                  </Text>
                ) : (<>
                  <Text as="p" color="font.primary" margin="small medium">
                      You have signed out of your TodoTik account.
                  </Text>
                    <Text as="p" color="font.primary" margin="small medium">
                        For added security, especially on shared devices,
                        clear the website data in your browser's privacy settings.
                    </Text>
                    <Text as="p" color="font.primary" margin="small medium">
                      If you suspect unauthorized activity, immediately change your password,
                      verify your email and phone details, and then sign out of all devices in your account settings.
                    </Text>
                </>)}
            </Card>
        </Flex>
      </View>
    );
}

export default SignOut;
