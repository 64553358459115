// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { AccountPhone, AccountEmail, IdDocument, VerificationSessionIdDocument, PersonalIdDocument, PersonalId, FederatedId, Account, AccountStatus, VerifiedSensitiveData, Image, VerificationSession } = initSchema(schema);

export {
  AccountPhone,
  AccountEmail,
  IdDocument,
  VerificationSessionIdDocument,
  PersonalIdDocument,
  PersonalId,
  FederatedId,
  Account,
  AccountStatus,
  VerifiedSensitiveData,
  Image,
  VerificationSession
};