import {listFederatedIds} from "../graphql/queries";
import {generateClient} from "aws-amplify/api";

const client = generateClient({authMode: 'userPool'});

export function getSocialIdentities(attributes) {
    if (!attributes || !attributes.identities) {
        return { primary: null, identities: null };
    }
    const identities = JSON.parse(attributes.identities);
    const primary = identities.find(i => Boolean(i.primary));
    return { primary, identities };
}

export async function listFederatedIdsByUsername(username) {
    const variables = {
        filter: {
            username: { eq: username }
        }, limit: 20 // TODO: Pagination
    };
    const result = await client.graphql({
        query: listFederatedIds,
        variables: variables
    });
    const federatedIds = result.data.listFederatedIds.items.filter(Boolean);
    if (federatedIds.length === 0) {
        return null;
    }
    return federatedIds;
};