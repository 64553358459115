import './App.css';
import {Outlet} from "react-router-dom";
import {LogoWithText} from './ui-components';
import {Card, useTheme, View} from "@aws-amplify/ui-react";
import React from "react";
import NavMenu from "./NavMenu";

function SignInLayout() {
    const { tokens } = useTheme();

    return (
        <div className="MainContainer">
            <div className="LeftBar column" style={{ backgroundColor: tokens.colors.brand.primary[80] }}>
                <LogoWithText color="brand" margin="xl xs 0 xxxs"/>
                <View backgroundColor="font.secondary">
                    <Card height="72px" backgroundColor="brand.primary.80" borderRadius="0 0 72px 0"></Card>
                </View>
                <Card backgroundColor="font.secondary" borderRadius="80px 0 0 48px">
                    <NavMenu />
                </Card>
                <View backgroundColor="font.secondary">
                    <Card height="112px" backgroundColor="brand.primary.80" borderRadius="0 112px 0 0"></Card>
                </View>
            </div>
            <div className="Main" style={{ backgroundColor: tokens.colors.font.secondary }}>
                <View className="fill-available" backgroundColor="font.secondary"
                    style={{ width: '100%', height: '100%',
                        overflowY: 'auto',
                        padding: `${tokens.space.xs} ${tokens.space.medium} ${tokens.space.xs} ${tokens.space.xs}`,
                        backgroundColor: `${tokens.colors.font.secondary}`
                    }}>
                    <Outlet />
                </View>
            </div>
        </div>
    );
}

export default SignInLayout;
