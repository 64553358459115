import { Link } from 'react-router-dom';

import './legal.css';
import { Text, View } from "@aws-amplify/ui-react";

const Abuse = () => {

    return (
        <View className="legal">

            <Text as="h1">Use Restrictions Policy</Text>
            <Text as="p">Last Updated: 1st of January, 2024</Text>
            <Text>
                This Use Restrictions Policy is subject to the terms outlined in
                our&nbsp;<Link to="/terms/">Terms of Service</Link>.
            </Text>

            <Text as="h2">Introduction</Text>
            <Text as="p">This Use Restrictions Policy outlines
                unacceptable behaviors and actions that are prohibited on TodoTik.
                By using our services, you agree to abide by these restrictions
                to ensure a safe and positive experience for all users.
            </Text>

            <Text as="h2">Prohibited Conduct</Text>
            <Text as="p">Users of TodoTik are prohibited from engaging in the following conduct:</Text>
            <ol>
            <li>Illegal Activities: Engaging in illegal activities or promoting illegal acts.</li>
            <li>Harmful or Dangerous Content: Posting or sharing content that is harmful,
                threatening, abusive, excessively violent, defamatory, or discriminatory.</li>
            <li>Harassment and Bullying: Harassing, bullying, or intimidating other users.</li>
            <li>Impersonation: Impersonating any person or entity, or falsely stating or
                otherwise misrepresenting your affiliation with a person or entity.</li>
            <li>Infringement of Intellectual Property: Uploading, posting, or otherwise
                transmitting content that infringes on any intellectual property or
                proprietary rights of others.</li>
            <li>We prohibit the intentional distribution of spam, chain letters,
                pyramid schemes, and the transmission of harmful code like viruses
                or malware. We recognize that sometimes users might unknowingly
                become conduits for such activities. In these cases, we aim to
                address the root source, not victims who might inadvertently be
                involved.</li>
            <li>Privacy Violations: Collecting or attempting to collect personal
                data about users or third parties without their consent; violating
                privacy or data protection rights.</li>
            <li>Disruption of Services: Interfering with or disrupting the services
                or servers or networks connected to the services.</li>
            </ol>

            <Text as="h2">Enforcement</Text>
            <Text as="p">Violation of this Use Restrictions Policy may result in
                a range of actions, including, but not limited to:</Text>
            <ul>
            <li>Warning</li>
            <li>Temporary suspension of account</li>
            <li>Permanent termination of account</li>
            <li>Legal action, if necessary</li>
            </ul>
            <Text>Additionally, we reserve the right to remove any content that
                violates these guidelines. Please be aware that individual
                communities within our Services may enforce their own content
                moderation rules, which should also be respected.
            </Text>

            <Text as="h2">Reporting Violations</Text>
            <Text as="p">
                If you encounter someone violating this policy, please
                report it to us immediately through our designated reporting
                channels.</Text>
            <ol>
                <li>Community moderators or admins</li>
                <li><a href="mailto:support@todotik.com">TodoTik Support team</a></li>
                <li><a href="mailto:legal@todotik.com">TodoTik Legal team</a></li>
                <li><a href="mailto:abuse@todotik.com">Email abuse contact</a> (spam, etc.)</li>
            </ol>

            <Text as="h2">Changes to the Policy</Text>
            <Text as="p">
            We reserve the right to reasonably modify this Use Restrictions Policy
                at any time. Changes will be effective immediately upon posting.
            </Text>
        </View>
    );
}
export default Abuse;
