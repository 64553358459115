import React, {useCallback, useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import VerifyButton from './VerifyButton';

import {
  Card,
  Flex,
  Image,
  Button,
  useAuthenticator,
  TextField, Text, View, useTheme
} from '@aws-amplify/ui-react';
import {Link, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import {useSession} from './SessionContext';
import {existingAccounts} from "./utils/accounts";
import SignInLoader from "./SignInLoader";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = STRIPE_PUBLISHABLE_KEY? loadStripe(STRIPE_PUBLISHABLE_KEY) : null;

const Profile = () => {
  const { accounts, setAccounts } = useSession();
  const [ account, setAccount ] =  useState();

  const { tokens } = useTheme();

  const { signOut } = useAuthenticator((context) => [context.user]);
  const { user } = useSession();
  const { userAttributes } = useSession();
  const { idToken } = useSession();
  const { authStatus } = useSession();

  const navigate = useNavigate();

  const checkAccount = useCallback(async () => {
    try {
      if (userAttributes && userAttributes.email_verified && userAttributes.sub) {
          // Only load accounts if the email is verified
          const { matchedAccounts } = await existingAccounts(user, userAttributes);
          setAccounts(matchedAccounts);
          if (matchedAccounts?.length > 0) {
            setAccount(matchedAccounts[0]);
            if (matchedAccounts.length > 1 || matchedAccounts[0].id !== idToken?.payload.acc) {
              console.warn(`Assumed ${
                  matchedAccounts.length === 1?
                      'the only matching account' :
                      'the first of ' + matchedAccounts.length + ' matching accounts'
              }: ${JSON.stringify(matchedAccounts, null, 2)}`);
            }
          }
      }
    } catch (error) {
        console.log(error);
    }
  }, [userAttributes, setAccounts, user]);

  function valueOrDefault(value) {
        return value || '?';
  };

  useEffect(() => {
    checkAccount().then();
  }, [userAttributes]);

  const handleSignOut = async () => {
    await signOut();
    navigate('/profile/');
  };

  const handleDeleteSuccess = () => {
    console.info('User has been successfully deleted')
    navigate('/');
  }

  return (
    <View
          overflow="hidden auto"
          width="100" height="100%"
          padding="0 0 xs xs"
          backgroundColor="font.secondary" marginTop="zero">
      {userAttributes? (
        <Flex alignItems="start" marginTop="zero">
          <Flex width="100%" direction="column" gap="xs" marginTop="zero">
            {authStatus !== 'authenticated'? (<>
              <SignInLoader/>
            </>):(<>
            </>)}
            <Flex width="100%" alignItems="start" gap="small" justifyContent="space-between" marginTop="0px">
              <Button textAlign="center" className="no-print" variation="primary"
                      padding="0 0 0 xxl"
                      margin="xs 0 0 0"
                      borderRadius="xxxl large large xxl"
                      >
                <Image backgroundColor="font.tertiary"
                       src="/logo192.png" alt="Avatar"
                       width="auto" maxHeight="192px"
                       padding="xxxs"
                       margin="0 0 0 medium"
                       borderColor="brand.secondary.60 brand.secondary.80"
                       borderStyle="dashed"
                       borderRadius="xxl large large xl"
                       borderWidth="medium"
                />
              </Button>
              <Flex direction="column" alignItems="start" justifyContent="start" marginTop="zero">
                <Card backgroundColor="brand.primary.100" padding="small medium small small" borderRadius="0 0 0 xl">
                  <Flex direction="column" gap="medium" paddingTop="large">
                    <Button className="no-print" variation="link" borderRadius="large large large large" onClick={handleSignOut}>Sign&nbsp;out</Button>
                    <Button className="no-print" variation="link" borderRadius="large large large xl" fontSize="small" onClick={() => navigate("/password/")}>Change&nbsp;password</Button>
                  </Flex>
                </Card>
              </Flex>
            </Flex>
            <Card backgroundColor="brand.primary.80" borderRadius="xl 0 0 xl">
              <Flex width="100%" alignItems="end" gap="xxxs">
                <TextField label="Name" value={valueOrDefault(userAttributes?.name)} width="100%" disabled={true} color="font.secondary" />
                <VerifyButton stripePromise={stripePromise} accountId={account?.id || idToken?.payload.acc} email={userAttributes?.email} />
              </Flex>
            </Card>
            <Card backgroundColor="brand.primary.80" borderRadius="xl 0 0 xl">
              <Flex width="100%" alignItems="end" gap="xxxs">
                <TextField label="Email" value={valueOrDefault(userAttributes?.email)} width="100%" disabled={true} color="font.tertiary" />
                <Button className="no-print" variation="primary" onClick={() => navigate("/email/")}>Update</Button>
              </Flex>
            </Card>
            <Card backgroundColor="brand.primary.80" borderRadius="xl 0 0 xl">
              <Flex width="100%" alignItems="end" gap="xxxs">
                <TextField label="Phone number" value={valueOrDefault(userAttributes?.phone_number)} width="100%" disabled={true} />
                <Button className="no-print" variation="primary" onClick={() => navigate("/phone-number/")}>Update</Button>
              </Flex>
            </Card>
            {authStatus === 'authenticated'?<>
            <Card backgroundColor="brand.primary.80" marginBottom="xl" borderRadius="xl 0 0 0">
              <Flex direction="column" width="100%" alignItems="start" justifyContent="space-between">
                <TextField label="Authenticated user identifier (sub)" width="100%" disabled={true} value={valueOrDefault(userAttributes?.sub)} />
                <TextField label="Authenticated technical username" width="100%" disabled={true} value={valueOrDefault(user?.username)} />
                <Link className="App-link" to="/delete/">Request to delete account</Link>
              </Flex>
            </Card>
            </>:<>
            </>}
          </Flex>
        </Flex>
      ) : (
        <View className="fill-available" backgroundColor="font.secondary">
          <Flex direction="column" justifyContent="space-around" alignItems="center">
            <SignInLoader/>
          </Flex>
        </View>
      )}
    </View>
  );
}

export default Profile;
