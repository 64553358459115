import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

import './App.css';

function Footer() {

  const { user } = useAuthenticator((context) => [context.user]);

  return (
      <div className="App-footer">
        <div className="App-detail">
          Support
          <div className="App-text"><a className="App-link" href="mailto:support@todotik.com">support@todotik.com</a>
          <br/><a className="App-link" rel="noreferrer" href="tel:+34951870170">+34951870170</a></div>
        </div>
        <div className="App-detail">
          Services
          <div className="App-text">
            <Link className="App-link" to="/consulting/">Consulting Services</Link>
            <br/><a className="App-link" rel="noreferrer" target="_blank"
            href={ 'https://pay.todotik.com/b/7sI02S25L4Ttf60cMM' +
              (user && user.signInDetails ? ('?prefilled_email=' + user.signInDetails.loginId) : '' )
            }>Pay TodoTik</a>
          </div>
        </div>
        <div className="App-detail">
          TodoTik, S.L.
          <div className="App-text">
            <Link className="App-link" to="/terms/">Terms of Service</Link>
            <br/><Link className="App-link" to="/privacy/">Privacy Policy</Link>
          </div>
        </div>
      </div>
  );
}

export default Footer;
