import {generateClient} from "aws-amplify/api";
import {createAccountPhone} from "../graphql/mutations";
import {getAccountPhone, listAccountPhones} from "../graphql/queries";

const client = generateClient({authMode: 'userPool'});

export async function newAccountPhone(accountId, phone, label, sms, verifiedAt = null) {
    const response = await client.graphql({
        mutation: createAccountPhone,
        variables: {
            accountId,
            phone,
            label,
            verifiedAt,
            detachedAt: null,
            redactedAt: null
        }
    });
    return response.data.createAccountPhone;
}

export async function getAccountPhoneByAccount(accountId) {
    const response = await client.graphql({
        query: getAccountPhone,
        variables: { accountId: accountId }
    });
    return response.data.getAccountPhone;
}

export async function listAccountPhonesByAccount(accountId) {
    const response = await client.graphql({
        query: listAccountPhones,
        variables: {accountId: accountId}
    });
    return response.data.listAccountPhones.items;
}

export async function listAccountPhonesByPhone(phone) {
    const response = await client.graphql({
        query: listAccountPhones, variables: {phone}
    });
    return response.data.listAccountPhones.items;
}