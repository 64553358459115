import React, { useEffect } from "react";
import {
  Authenticator,
  Button, Card, Flex,
  Heading,
  Image, Loader,
  Text,
  useAuthenticator,
  useTheme,
  View
} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import {Link, useNavigate} from "react-router-dom";
import Logo from "./assets/logo.svg";

const CustomAuthenticator = () => {
  const { authStatus, user } = useAuthenticator((context) => [context.user, context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/profile/');
    }
  }, [authStatus, navigate]);

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
          <View textAlign="center" padding={tokens.space.large}>
            <Link className="App-link" to="/">Back to Start</Link>
          </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              &copy; All Rights Reserved
            </Text>
          </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
            <Heading padding="xl zero zero xl" level="4">
              <Image src={Logo} alt="TodoTik logo" width="medium" height="auto"
                     marginRight="small"/>
              Sign in to your account
            </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();

        return (
            <View textAlign="center">
              <Button
                  fontWeight="normal"
                  onClick={toForgotPassword}
                  size="small"
                  variation="link"
              >
                Reset Password
              </Button>
            </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading padding="xl zero zero xl" level="4">
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
            <View textAlign="center">
              <Button
                  fontWeight="normal"
                  onClick={toSignIn}
                  size="small"
                  variation="link"
              >
                Back to Sign In
              </Button>
            </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
            <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
            >
              Confirm sign-up
            </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
            <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
            >
              Time-based One-Time Password (TOTP)
            </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
            <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
            >
              Confirm sign-in
            </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
            <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
            >
              Reset password
            </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
            <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
            >
              Confirm reset password
            </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
      password: {
        placeholder: 'Enter your password'
      }
    },
    signUp: {
      email: {
        placeholder: 'Enter your email',
        isRequired: true,
        order: 1,
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password',
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm password',
        placeholder: 'Please confirm your password',
        order: 3,
      },
      phone_number: {
        label: 'Phone number',
        placeholder: 'Enter your phone number',
        type: 'tel',
        order: 4,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your password',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your confirmation code',
        label: 'Confirmation code',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your password please',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'Confirmation code',
        placeholder: 'Enter your confirmation code',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation code',
        placeholder: 'Enter your confirmation code',
        isRequired: false,
      },
    }
  };

  return (
    <Authenticator formFields={formFields} components={components}>
      <View className="fill-available" backgroundColor="font.secondary">
        <Flex direction="column" justifyContent="space-around" alignItems="center">
          <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.active" margin="xl medium xs medium">
            Signing In...
          </Text>
            <Card variation="elevated" backgroundColor="brand.primary.90" maxWidth="300pt" margin="xs xl"
                  padding="large" borderRadius="xl">
              <Loader variation="linear"/>
              <Text as="p" color="font.primary" margin="small medium">
                This should only take a few seconds...
              </Text>
            </Card>
        </Flex>
      </View>
    </Authenticator>
  );
}

export default CustomAuthenticator;
