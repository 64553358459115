/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://4hsykeo2l5hzfmvdrno4agg5de.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-cm5bbp5laffmtkctu7iven42ai",
    "aws_cloud_logic_custom": [
        {
            "name": "TodoTikStripe",
            "endpoint": "https://np5lafwk9d.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:c8d9cba0-dadf-4ecc-ac7e-95a6604b6ed9",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_29dblf9Vm",
    "aws_user_pools_web_client_id": "dikoojiirrk0uk1dtulbqmrtr",
    "oauth": {
        "domain": "todotik-main.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://todotik.com/signin/",
        "redirectSignOut": "https://todotik.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "todotik-content231143-main",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "TodoTikIndex-main"
                ],
                "default": "TodoTikIndex-main"
            },
            "maps": {
                "items": {
                    "hereHybrid-main": {
                        "style": "HybridHereExploreSatellite"
                    }
                },
                "default": "hereHybrid-main"
            }
        }
    }
};


export default awsmobile;
