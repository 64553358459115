import {Text, useAuthenticator} from "@aws-amplify/ui-react";
import {Link} from "react-router-dom";
import React from "react";

function NavMenu() {

    const {user} = useAuthenticator((context) => [context.user]);

    return (
      <nav>
          <ul className="menu no-print">
              <li style={{listStyleType: "'🏠'"}}>
                  <Link to="/">
                      <Text fontSize="large" className="App-link">Start</Text>
                  </Link>
              </li>
              <li style={{listStyleType: "'🌍'"}}>
                  <Link to="/map/">
                      <Text fontSize="large" className="App-link">Map</Text>
                  </Link>
              </li>
              {
                  user ? (
                      <li style={{listStyleType: "'👨'"}}>
                          <Link to="/profile/">
                              <Text fontSize="large" className="App-link">Profile</Text>
                          </Link>
                      </li>
                  ) : (
                      ''
                  )
              }
              <li style={{listStyleType: "'⚖️'"}}>
                  <Link to="/terms/">
                      <Text fontSize="large" className="App-link">Terms</Text>
                  </Link>
              </li>
          </ul>
      </nav>
    );
}

export default NavMenu;