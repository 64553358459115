/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function Logo(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      variantValues: { color: "neutral" },
      overrides: {
        Vector38621798: {},
        Vector38621799: {},
        Vector38621800: {},
        Group: {},
        "TodoTik logo": {},
        Logo: {},
      },
    },
    {
      variantValues: { color: "brand" },
      overrides: {
        Vector38621798: {
          paths: [
            {
              d: "M18.8645 1.72996C18.8645 2.13004 18.8493 2.53096 18.8687 2.93019C18.8873 3.29988 18.7134 3.51511 18.407 3.69067C17.6212 4.14054 16.8219 4.57438 16.0733 5.0808C14.4755 6.16202 13.152 7.52852 12.0236 9.09421C11.063 10.4261 10.3642 11.8804 9.86197 13.4368C9.83918 13.5069 9.81893 13.5778 9.78854 13.6444C9.71258 13.8124 9.59779 13.9263 9.39691 13.9221C9.18843 13.9179 9.12006 13.7668 9.07533 13.6065C8.82971 12.7236 8.51657 11.866 8.0878 11.0549C6.64365 8.32107 4.62217 6.12657 1.96682 4.52796C1.49838 4.24605 1.02318 3.97511 0.540394 3.72105C0.170704 3.52608 -0.0158287 3.26865 0.00105212 2.82721C0.0297495 2.06842 0.0069602 1.30879 0.00611616 0.549151C0.00611616 -0.0146682 0.196869 -0.128614 0.697385 0.144011C2.48169 1.11719 4.26514 2.09206 6.0486 3.06692C7.00827 3.59192 7.96963 4.11353 8.92424 4.64697C9.29815 4.85544 9.64505 4.83266 10.0122 4.63009C12.7224 3.1412 15.4352 1.65822 18.1479 0.174397C18.721 -0.138742 18.8662 -0.0501179 18.8653 0.603169C18.8645 0.978767 18.8653 1.35436 18.8653 1.72996L18.8645 1.72996Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Vector38621799: {
          paths: [
            {
              d: "M7.1489 1.76947C7.1489 1.32889 7.15143 0.887451 7.14805 0.446862C7.14468 0.0594475 6.88471 -0.111049 6.54372 0.0763284C5.38148 0.714423 4.22599 1.36433 3.06881 2.01171C2.15049 2.52067 1.23386 3.03385 0.317236 3.54618C0.10707 3.6635 0.00409808 3.84834 0.00409808 4.08974C0.00325404 4.96332 -0.00518682 5.8369 0.00494166 6.71048C0.0100059 7.13082 0.279255 7.2819 0.647256 7.07849C1.61115 6.54505 2.57167 6.0074 3.53219 5.46975C4.62522 4.86373 5.71487 4.25264 6.80537 3.64324C7.03917 3.51242 7.15565 3.3242 7.14552 3.04397C7.13033 2.62027 7.1413 2.19487 7.1413 1.77032L7.1489 1.77032L7.1489 1.76947Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Vector38621800: {
          paths: [
            {
              d: "M10.8528 1.79591L10.8519 1.79591C10.8519 1.33928 10.8561 0.881813 10.8502 0.425188C10.8452 0.0445255 10.5903 -0.105714 10.2619 0.0782871C9.09632 0.731574 7.93323 1.39077 6.76593 2.04237C6.76255 2.04406 6.76086 2.04574 6.75749 2.04743C6.7406 2.05419 6.72288 2.06094 6.70516 2.06938C6.66042 2.08963 6.61822 2.11411 6.57517 2.13775C5.81976 2.55808 5.05759 2.96828 4.31061 3.4038C3.85736 3.66799 3.44716 3.68571 2.98294 3.40549C2.21486 2.94212 1.42315 2.51672 0.632287 2.09217C0.235588 1.87947 0.00432067 2.03477 0.00432067 2.48465C0.00347663 3.33375 0.0119168 4.1837 0.000100216 5.0328C-0.00411998 5.33413 0.125019 5.52825 0.374011 5.66499C1.3109 6.17985 2.24778 6.69387 3.18382 7.21043C3.49527 7.38261 3.80588 7.39021 4.11733 7.21887C5.06941 6.69725 6.01979 6.1731 6.97018 5.65064C7.00057 5.63376 7.02758 5.61603 7.0529 5.59662C7.08666 5.58227 7.12127 5.56624 7.15756 5.54682C7.38714 5.42359 7.60997 5.28602 7.83701 5.15857C8.69793 4.67746 9.55886 4.19467 10.4223 3.71779C10.7228 3.55236 10.8781 3.327 10.857 2.96828C10.8359 2.58002 10.8528 2.18754 10.8528 1.79591Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Group: {},
        "TodoTik logo": {},
        Logo: {},
      },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Logo")}
      {...rest}
    >
      <View
        width="32px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "TodoTik logo")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="18.87px"
          height="22.83px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="calc(50% - 11.41px - -0.41px)"
          left="calc(50% - 9.44px - -0.44px)"
          {...getOverrideProps(overrides, "Group")}
        >
          <Icon
            width="18.87px"
            height="13.92px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 18.870075225830078,
              height: 13.922236442565918,
            }}
            paths={[
              {
                d: "M18.8645 1.72996C18.8645 2.13004 18.8493 2.53096 18.8687 2.93019C18.8873 3.29988 18.7134 3.51511 18.407 3.69067C17.6212 4.14054 16.8219 4.57438 16.0733 5.0808C14.4755 6.16202 13.152 7.52852 12.0236 9.09421C11.063 10.4261 10.3642 11.8804 9.86197 13.4368C9.83918 13.5069 9.81893 13.5778 9.78854 13.6444C9.71258 13.8124 9.59779 13.9263 9.39691 13.9221C9.18843 13.9179 9.12006 13.7668 9.07533 13.6065C8.82971 12.7236 8.51657 11.866 8.0878 11.0549C6.64365 8.32107 4.62217 6.12657 1.96682 4.52796C1.49838 4.24605 1.02318 3.97511 0.540394 3.72105C0.170704 3.52608 -0.0158287 3.26865 0.00105212 2.82721C0.0297495 2.06842 0.0069602 1.30879 0.00611616 0.549151C0.00611616 -0.0146682 0.196869 -0.128614 0.697385 0.144011C2.48169 1.11719 4.26514 2.09206 6.0486 3.06692C7.00827 3.59192 7.96963 4.11353 8.92424 4.64697C9.29815 4.85544 9.64505 4.83266 10.0122 4.63009C12.7224 3.1412 15.4352 1.65822 18.1479 0.174397C18.721 -0.138742 18.8662 -0.0501179 18.8653 0.603169C18.8645 0.978767 18.8653 1.35436 18.8653 1.72996L18.8645 1.72996Z",
                fill: "rgba(174,179,183,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="39.02%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector38621798")}
          ></Icon>
          <Icon
            width="7.15px"
            height="7.17px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 7.149808883666992,
              height: 7.16815185546875,
            }}
            paths={[
              {
                d: "M7.1489 1.76947C7.1489 1.32889 7.15143 0.887451 7.14805 0.446862C7.14468 0.0594475 6.88471 -0.111049 6.54372 0.0763284C5.38148 0.714423 4.22599 1.36433 3.06881 2.01171C2.15049 2.52067 1.23386 3.03385 0.317236 3.54618C0.10707 3.6635 0.00409808 3.84834 0.00409808 4.08974C0.00325404 4.96332 -0.00518682 5.8369 0.00494166 6.71048C0.0100059 7.13082 0.279255 7.2819 0.647256 7.07849C1.61115 6.54505 2.57167 6.0074 3.53219 5.46975C4.62522 4.86373 5.71487 4.25264 6.80537 3.64324C7.03917 3.51242 7.15565 3.3242 7.14552 3.04397C7.13033 2.62027 7.1413 2.19487 7.1413 1.77032L7.1489 1.77032L7.1489 1.76947Z",
                fill: "rgba(174,179,183,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="19.68%"
            bottom="48.92%"
            left="46.23%"
            right="15.88%"
            {...getOverrideProps(overrides, "Vector38621799")}
          ></Icon>
          <Icon
            width="10.86px"
            height="7.34px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 10.858887672424316,
              height: 7.343537330627441,
            }}
            paths={[
              {
                d: "M10.8528 1.79591L10.8519 1.79591C10.8519 1.33928 10.8561 0.881813 10.8502 0.425188C10.8452 0.0445255 10.5903 -0.105714 10.2619 0.0782871C9.09632 0.731574 7.93323 1.39077 6.76593 2.04237C6.76255 2.04406 6.76086 2.04574 6.75749 2.04743C6.7406 2.05419 6.72288 2.06094 6.70516 2.06938C6.66042 2.08963 6.61822 2.11411 6.57517 2.13775C5.81976 2.55808 5.05759 2.96828 4.31061 3.4038C3.85736 3.66799 3.44716 3.68571 2.98294 3.40549C2.21486 2.94212 1.42315 2.51672 0.632287 2.09217C0.235588 1.87947 0.00432067 2.03477 0.00432067 2.48465C0.00347663 3.33375 0.0119168 4.1837 0.000100216 5.0328C-0.00411998 5.33413 0.125019 5.52825 0.374011 5.66499C1.3109 6.17985 2.24778 6.69387 3.18382 7.21043C3.49527 7.38261 3.80588 7.39021 4.11733 7.21887C5.06941 6.69725 6.01979 6.1731 6.97018 5.65064C7.00057 5.63376 7.02758 5.61603 7.0529 5.59662C7.08666 5.58227 7.12127 5.56624 7.15756 5.54682C7.38714 5.42359 7.60997 5.28602 7.83701 5.15857C8.69793 4.67746 9.55886 4.19467 10.4223 3.71779C10.7228 3.55236 10.8781 3.327 10.857 2.96828C10.8359 2.58002 10.8528 2.18754 10.8528 1.79591Z",
                fill: "rgba(174,179,183,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="67.83%"
            left="12.25%"
            right="30.2%"
            {...getOverrideProps(overrides, "Vector38621800")}
          ></Icon>
        </View>
      </View>
    </Flex>
  );
}
