import { Link } from 'react-router-dom';

import './legal.css';
import { Text, View } from "@aws-amplify/ui-react";

const Terms = () => {

    return (
        <View className="legal">
            <Text as="h1">Terms of Service</Text>
            <Text as="p">Last Updated: 1st of January, 2024</Text>

            <Text as="p">
                Thank you for choosing TodoTik.
                We're committed to providing you with a service that respects your rights and fosters trust.
                By using any TodoTik products or services, you agree to these Terms of Service ("Terms").
                Please know that violating these terms may result in the termination of your account.
                We appreciate your understanding and cooperation in helping us
                maintain a respectful and safe environment for every member of our community.
            </Text>

            <Text as="h2">Updates to Terms</Text>
            <Text as="p">We may update these Terms at any time. Significant changes will be notified to users subscribed
                to our policy updates mailing list and the update date at the top of this page will be refreshed.</Text>

            <Text as="h2">Definitions</Text>
            <dl>
                <dt>Company/We/Our/Us</dt>
                <dd>Refers to TodoTik, S.L.</dd>
                <dt>Services</dt>
                <dd>Our website (todotik.com) and any TodoTik product, including all versions and formats.</dd>
                <dt>You/Your</dt>
                <dd>Users or organizations owning an account with our Services.</dd>
            </dl>

            <Text as="h2">Account Terms</Text>
            <ul>
                <li>You are responsible for maintaining the security of your account.
                    If you suspect that your account has been compromised,
                    please change your password as soon as possible and alert us.
                    Keeping your password confidential is essential
                    to prevent unauthorized access.
                </li>
                <li>Misuse of the Services, as outlined in our <Link to="/abuse/">Use Restrictions Policy</Link>,
                    is not allowed.</li>
                <li>You're accountable for all content and activity under your account.</li>
                <li>Accounts must be registered by humans, not bots or automated methods.</li>
            </ul>

            <Text as="h2">Payments and Refunds</Text>
            <ul>
                <li>Free versions of our Services are truly free, though they come with
                    limitations on features that require significant data storage or
                    enhanced security measures.
                </li>
                <li>Paid Services may offer a free trial, after which payment is required.</li>
                <li>Fees include taxes when explicitly detailed in your invoices and receipts.
                    If not itemized, taxes are excluded from the fees but will be collected
                    and remitted where required.
                </li>
                <li>Refunds are processed based on the payment provider's refund policy.</li>
            </ul>

            <Text as="h2">Cancellation and Termination</Text>
            <ul>
                <li>Account cancellation must be done as per our Cancellation Policy.</li>
                <li>Upon cancellation, content will be deleted as specified in the policy.</li>
                <li>We reserve the right to suspend or terminate accounts for any reason.</li>
            </ul>

            <Text as="h2">Modifications to Service and Prices</Text>
            <ul>
                <li>We may modify or discontinue parts of our Services.</li>
                <li>Price changes will be notified at least 30 days in advance.</li>
            </ul>

            <Text as="h2">Uptime, Security, and Privacy</Text>
            <ul>
                <li>Services are provided "as is" and "as available".</li>
                <li>Excessive use of our services may lead to temporary limitations
                    on your account to maintain overall service quality for all users.
                </li>
                <li>We process data as per our <Link to="/privacy/">Privacy Policy</Link>
                    &nbsp;and applicable laws.</li>
            </ul>

            <Text as="h2">Compliance with European Data Protection</Text>
            <ul>
                <li>We comply with the EU General Data Protection Regulation (GDPR)
                    and UK GDPR, ensuring the highest standards of data protection.</li>
                <li>In alignment with these regulations, we process customer data
                    exclusively within the European Union.</li>
            </ul>
            <Text as="h2">Copyright and Content Ownership</Text>
            <ul>
                <li>You content must comply with copyright laws.</li>
                <li>You retain ownership of your content but grant us a
                    limited license to use it for providing the Services.
                </li>
                <li>We own the rights to the Services and its intellectual property.</li>
            </ul>

            <Text as="h2">API Use</Text>
            <ul>
                <li>API use is subject to these Terms and specific API terms.</li>
                <li>Abuse of the API may lead to account suspension.</li>
            </ul>

            <Text as="h2">Liability</Text>
            <ul>
                <li>We endeavor to consistently provide a high standard of service.
                    However, we cannot be held liable for damages that are not a
                    direct result of our own failure. This includes issues arising
                    from the use of our Services, data access, or unauthorized
                    account access. In these instances, our liability is limited to
                    the fees you have paid for the relevant subscription period, in
                    accordance with applicable EU consumer protection laws.
                </li>
            </ul>

            <Text as="h2">Questions?</Text>
            <Text as="p">
                <a href="mailto:support@todotik.com">Contact our Support team</a>&nbsp;for
                any inquiries about these Terms.
            </Text>

        </View>
    );
}

export default Terms;
