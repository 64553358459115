/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountPhone = /* GraphQL */ `
  query GetAccountPhone($id: ID!) {
    getAccountPhone(id: $id) {
      id
      accountId
      label
      phoneNumber
      sms
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccountPhones = /* GraphQL */ `
  query ListAccountPhones(
    $id: ID
    $filter: ModelAccountPhoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccountPhones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        label
        phoneNumber
        sms
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccountPhones = /* GraphQL */ `
  query SyncAccountPhones(
    $filter: ModelAccountPhoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountPhones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        label
        phoneNumber
        sms
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountPhonesByAccountIdAndPhoneNumber = /* GraphQL */ `
  query AccountPhonesByAccountIdAndPhoneNumber(
    $accountId: ID!
    $phoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountPhoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountPhonesByAccountIdAndPhoneNumber(
      accountId: $accountId
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        label
        phoneNumber
        sms
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountPhonesByPhoneNumberAndAccountId = /* GraphQL */ `
  query AccountPhonesByPhoneNumberAndAccountId(
    $phoneNumber: String!
    $accountId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountPhoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountPhonesByPhoneNumberAndAccountId(
      phoneNumber: $phoneNumber
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        label
        phoneNumber
        sms
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccountEmail = /* GraphQL */ `
  query GetAccountEmail($id: ID!) {
    getAccountEmail(id: $id) {
      id
      accountId
      email
      label
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccountEmails = /* GraphQL */ `
  query ListAccountEmails(
    $id: ID
    $filter: ModelAccountEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccountEmails(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        email
        label
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccountEmails = /* GraphQL */ `
  query SyncAccountEmails(
    $filter: ModelAccountEmailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        email
        label
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountEmailsByAccountIdAndEmail = /* GraphQL */ `
  query AccountEmailsByAccountIdAndEmail(
    $accountId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountEmailsByAccountIdAndEmail(
      accountId: $accountId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        email
        label
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountEmailsByEmailAndAccountId = /* GraphQL */ `
  query AccountEmailsByEmailAndAccountId(
    $email: AWSEmail!
    $accountId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountEmailsByEmailAndAccountId(
      email: $email
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        email
        label
        verifiedAt
        detachedAt
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIdDocument = /* GraphQL */ `
  query GetIdDocument($id: ID!) {
    getIdDocument(id: $id) {
      id
      accountId
      expires
      issued
      issuingCountry
      number
      type
      verifiedAt
      vsId
      imageIds
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIdDocuments = /* GraphQL */ `
  query ListIdDocuments(
    $id: ID
    $filter: ModelIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIdDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        expires
        issued
        issuingCountry
        number
        type
        verifiedAt
        vsId
        imageIds
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIdDocuments = /* GraphQL */ `
  query SyncIdDocuments(
    $filter: ModelIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIdDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        expires
        issued
        issuingCountry
        number
        type
        verifiedAt
        vsId
        imageIds
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const idDocumentsByAccountIdAndTypeAndNumber = /* GraphQL */ `
  query IdDocumentsByAccountIdAndTypeAndNumber(
    $accountId: ID!
    $typeNumber: ModelIdDocumentAccountIndexCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    idDocumentsByAccountIdAndTypeAndNumber(
      accountId: $accountId
      typeNumber: $typeNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        expires
        issued
        issuingCountry
        number
        type
        verifiedAt
        vsId
        imageIds
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const idDocumentsByNumberAndType = /* GraphQL */ `
  query IdDocumentsByNumberAndType(
    $number: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    idDocumentsByNumberAndType(
      number: $number
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        expires
        issued
        issuingCountry
        number
        type
        verifiedAt
        vsId
        imageIds
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const idDocumentsByVsId = /* GraphQL */ `
  query IdDocumentsByVsId(
    $vsId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    idDocumentsByVsId(
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        expires
        issued
        issuingCountry
        number
        type
        verifiedAt
        vsId
        imageIds
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVerificationSessionIdDocument = /* GraphQL */ `
  query GetVerificationSessionIdDocument($id: ID!) {
    getVerificationSessionIdDocument(id: $id) {
      id
      documentId
      vsId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVerificationSessionIdDocuments = /* GraphQL */ `
  query ListVerificationSessionIdDocuments(
    $id: ID
    $filter: ModelVerificationSessionIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVerificationSessionIdDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        documentId
        vsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVerificationSessionIdDocuments = /* GraphQL */ `
  query SyncVerificationSessionIdDocuments(
    $filter: ModelVerificationSessionIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerificationSessionIdDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        vsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verificationSessionIdDocumentsByDocumentId = /* GraphQL */ `
  query VerificationSessionIdDocumentsByDocumentId(
    $documentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationSessionIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationSessionIdDocumentsByDocumentId(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        vsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verificationSessionIdDocumentsByVsId = /* GraphQL */ `
  query VerificationSessionIdDocumentsByVsId(
    $vsId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationSessionIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationSessionIdDocumentsByVsId(
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        vsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPersonalIdDocument = /* GraphQL */ `
  query GetPersonalIdDocument($id: ID!) {
    getPersonalIdDocument(id: $id) {
      id
      personalId
      documentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPersonalIdDocuments = /* GraphQL */ `
  query ListPersonalIdDocuments(
    $id: ID
    $filter: ModelPersonalIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersonalIdDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personalId
        documentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPersonalIdDocuments = /* GraphQL */ `
  query SyncPersonalIdDocuments(
    $filter: ModelPersonalIdDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPersonalIdDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        personalId
        documentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const personalIdDocumentsByPersonalId = /* GraphQL */ `
  query PersonalIdDocumentsByPersonalId(
    $personalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalIdDocumentsByPersonalId(
      personalId: $personalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personalId
        documentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const personalIdDocumentsByDocumentId = /* GraphQL */ `
  query PersonalIdDocumentsByDocumentId(
    $documentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalIdDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalIdDocumentsByDocumentId(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personalId
        documentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPersonalId = /* GraphQL */ `
  query GetPersonalId($id: ID!) {
    getPersonalId(id: $id) {
      id
      accountId
      issuer
      idNumberType
      idNumber
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPersonalIds = /* GraphQL */ `
  query ListPersonalIds(
    $id: ID
    $filter: ModelPersonalIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersonalIds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        issuer
        idNumberType
        idNumber
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPersonalIds = /* GraphQL */ `
  query SyncPersonalIds(
    $filter: ModelPersonalIdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPersonalIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        issuer
        idNumberType
        idNumber
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const personalIdsByAccountIdAndIdNumberTypeAndIdNumber = /* GraphQL */ `
  query PersonalIdsByAccountIdAndIdNumberTypeAndIdNumber(
    $accountId: ID!
    $idNumberTypeIdNumber: ModelPersonalIdAccountIndexCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalIdsByAccountIdAndIdNumberTypeAndIdNumber(
      accountId: $accountId
      idNumberTypeIdNumber: $idNumberTypeIdNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        issuer
        idNumberType
        idNumber
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const personalIdsByIdNumberAndIdNumberTypeAndIssuer = /* GraphQL */ `
  query PersonalIdsByIdNumberAndIdNumberTypeAndIssuer(
    $idNumber: String!
    $idNumberTypeIssuer: ModelPersonalIdIdNumberIndexCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalIdsByIdNumberAndIdNumberTypeAndIssuer(
      idNumber: $idNumber
      idNumberTypeIssuer: $idNumberTypeIssuer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        issuer
        idNumberType
        idNumber
        status
        redactedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFederatedId = /* GraphQL */ `
  query GetFederatedId($id: ID!) {
    getFederatedId(id: $id) {
      id
      accountId
      username
      provider
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFederatedIds = /* GraphQL */ `
  query ListFederatedIds(
    $id: ID
    $filter: ModelFederatedIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFederatedIds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        username
        provider
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFederatedIds = /* GraphQL */ `
  query SyncFederatedIds(
    $filter: ModelFederatedIdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFederatedIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        username
        provider
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const federatedIdsByAccountIdAndProvider = /* GraphQL */ `
  query FederatedIdsByAccountIdAndProvider(
    $accountId: ID!
    $provider: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFederatedIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    federatedIdsByAccountIdAndProvider(
      accountId: $accountId
      provider: $provider
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        username
        provider
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const federatedIdsByUsername = /* GraphQL */ `
  query FederatedIdsByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFederatedIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    federatedIdsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        username
        provider
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const federatedIdsByUserIdAndProvider = /* GraphQL */ `
  query FederatedIdsByUserIdAndProvider(
    $userId: String!
    $provider: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFederatedIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    federatedIdsByUserIdAndProvider(
      userId: $userId
      provider: $provider
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        username
        provider
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      accOwn
      sub
      birthdate
      email
      familyName
      givenName
      gender
      imageId
      locale
      middleName
      name
      nickname
      username
      zoneInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $id: ID
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accOwn
        sub
        birthdate
        email
        familyName
        givenName
        gender
        imageId
        locale
        middleName
        name
        nickname
        username
        zoneInfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accOwn
        sub
        birthdate
        email
        familyName
        givenName
        gender
        imageId
        locale
        middleName
        name
        nickname
        username
        zoneInfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountsBySub = /* GraphQL */ `
  query AccountsBySub(
    $sub: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accOwn
        sub
        birthdate
        email
        familyName
        givenName
        gender
        imageId
        locale
        middleName
        name
        nickname
        username
        zoneInfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountsByEmail = /* GraphQL */ `
  query AccountsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accOwn
        sub
        birthdate
        email
        familyName
        givenName
        gender
        imageId
        locale
        middleName
        name
        nickname
        username
        zoneInfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountsByUsername = /* GraphQL */ `
  query AccountsByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accOwn
        sub
        birthdate
        email
        familyName
        givenName
        gender
        imageId
        locale
        middleName
        name
        nickname
        username
        zoneInfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccountStatus = /* GraphQL */ `
  query GetAccountStatus($id: ID!) {
    getAccountStatus(id: $id) {
      id
      accountId
      lastSignInAt
      licenseStatus
      redactedAt
      signInAttemptAt
      signInAttempts
      suspendedAt
      suspendedReason
      verificationAttemptAt
      verificationAttempts
      verifiedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccountStatuses = /* GraphQL */ `
  query ListAccountStatuses(
    $id: ID
    $filter: ModelAccountStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccountStatuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        lastSignInAt
        licenseStatus
        redactedAt
        signInAttemptAt
        signInAttempts
        suspendedAt
        suspendedReason
        verificationAttemptAt
        verificationAttempts
        verifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccountStatuses = /* GraphQL */ `
  query SyncAccountStatuses(
    $filter: ModelAccountStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        lastSignInAt
        licenseStatus
        redactedAt
        signInAttemptAt
        signInAttempts
        suspendedAt
        suspendedReason
        verificationAttemptAt
        verificationAttempts
        verifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountStatusesByAccountId = /* GraphQL */ `
  query AccountStatusesByAccountId(
    $accountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountStatusesByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        lastSignInAt
        licenseStatus
        redactedAt
        signInAttemptAt
        signInAttempts
        suspendedAt
        suspendedReason
        verificationAttemptAt
        verificationAttempts
        verifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVerifiedSensitiveData = /* GraphQL */ `
  query GetVerifiedSensitiveData($id: ID!) {
    getVerifiedSensitiveData(id: $id) {
      id
      accountId
      vsId
      expiresAt
      redactedAt
      birthdate
      idNumber
      idNumberType
      document
      documentImages
      faceImages
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVerifiedSensitiveData = /* GraphQL */ `
  query ListVerifiedSensitiveData(
    $id: ID
    $filter: ModelVerifiedSensitiveDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVerifiedSensitiveData(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        vsId
        expiresAt
        redactedAt
        birthdate
        idNumber
        idNumberType
        document
        documentImages
        faceImages
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVerifiedSensitiveData = /* GraphQL */ `
  query SyncVerifiedSensitiveData(
    $filter: ModelVerifiedSensitiveDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerifiedSensitiveData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        vsId
        expiresAt
        redactedAt
        birthdate
        idNumber
        idNumberType
        document
        documentImages
        faceImages
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verifiedSensitiveDataByAccountIdAndVsId = /* GraphQL */ `
  query VerifiedSensitiveDataByAccountIdAndVsId(
    $accountId: ID!
    $vsId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVerifiedSensitiveDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verifiedSensitiveDataByAccountIdAndVsId(
      accountId: $accountId
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        vsId
        expiresAt
        redactedAt
        birthdate
        idNumber
        idNumberType
        document
        documentImages
        faceImages
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verifiedSensitiveDataByVsId = /* GraphQL */ `
  query VerifiedSensitiveDataByVsId(
    $vsId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVerifiedSensitiveDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verifiedSensitiveDataByVsId(
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        vsId
        expiresAt
        redactedAt
        birthdate
        idNumber
        idNumberType
        document
        documentImages
        faceImages
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      accountId
      url
      bytes
      filename
      redactedAt
      exif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $id: ID
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listImages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        url
        bytes
        filename
        redactedAt
        exif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountId
        url
        bytes
        filename
        redactedAt
        exif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByAccountIdAndFilename = /* GraphQL */ `
  query ImagesByAccountIdAndFilename(
    $accountId: ID!
    $filename: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByAccountIdAndFilename(
      accountId: $accountId
      filename: $filename
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        url
        bytes
        filename
        redactedAt
        exif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByUrlAndAccountId = /* GraphQL */ `
  query ImagesByUrlAndAccountId(
    $url: AWSURL!
    $accountId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByUrlAndAccountId(
      url: $url
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        url
        bytes
        filename
        redactedAt
        exif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVerificationSession = /* GraphQL */ `
  query GetVerificationSession($id: ID!) {
    getVerificationSession(id: $id) {
      id
      vsId
      cognitoIdentityId
      accountId
      email
      livemode
      type
      status
      redactedAt
      lastError
      lastVerificationReport
      phoneNumber
      givenNames
      familyNames
      address
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVerificationSessions = /* GraphQL */ `
  query ListVerificationSessions(
    $id: ID
    $filter: ModelVerificationSessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVerificationSessions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vsId
        cognitoIdentityId
        accountId
        email
        livemode
        type
        status
        redactedAt
        lastError
        lastVerificationReport
        phoneNumber
        givenNames
        familyNames
        address
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVerificationSessions = /* GraphQL */ `
  query SyncVerificationSessions(
    $filter: ModelVerificationSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerificationSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vsId
        cognitoIdentityId
        accountId
        email
        livemode
        type
        status
        redactedAt
        lastError
        lastVerificationReport
        phoneNumber
        givenNames
        familyNames
        address
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verificationSessionsByVsId = /* GraphQL */ `
  query VerificationSessionsByVsId(
    $vsId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationSessionsByVsId(
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vsId
        cognitoIdentityId
        accountId
        email
        livemode
        type
        status
        redactedAt
        lastError
        lastVerificationReport
        phoneNumber
        givenNames
        familyNames
        address
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verificationSessionsByAccountIdAndVsId = /* GraphQL */ `
  query VerificationSessionsByAccountIdAndVsId(
    $accountId: ID!
    $vsId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationSessionsByAccountIdAndVsId(
      accountId: $accountId
      vsId: $vsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vsId
        cognitoIdentityId
        accountId
        email
        livemode
        type
        status
        redactedAt
        lastError
        lastVerificationReport
        phoneNumber
        givenNames
        familyNames
        address
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const verificationSessionsByEmail = /* GraphQL */ `
  query VerificationSessionsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelVerificationSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verificationSessionsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vsId
        cognitoIdentityId
        accountId
        email
        livemode
        type
        status
        redactedAt
        lastError
        lastVerificationReport
        phoneNumber
        givenNames
        familyNames
        address
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
