import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import { useTheme } from '@aws-amplify/ui-react';

import { addTransparency } from './utils/addTransparency';

import Footer from './Footer';

function BottomSheet() {
  const { tokens } = useTheme();

  const handleHeight = 5; // triple to include handle margin

  const [position, setPosition] = useState({ x: 0, y: -Math.round(window.innerHeight/5) }); // Initial position

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight + position.y < 3*handleHeight) {
        setPosition({ x: 0, y: -window.innerHeight + 3*handleHeight });
      }
      else if (position.y > -handleHeight) {
        setPosition({ x: 0, y: -Math.round(window.innerHeight/5) });
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, [position.y, handleHeight]);

  const handleDrag = (e, data) => {
    // Update the position state when dragging
    setPosition({ x: 0, y: data.y });
  };

  const handleStop = (e, data) => {
    // Update the position state when dragging stops
    if (data.y > -handleHeight) {
      setPosition({ x: 0, y: -2*handleHeight });
    }
  };

  const contentStyle = {
    position: 'absolute',
    top: `${3 * handleHeight}px`,
    left: '0px',
    width: '100%',
    height: `${-position.y}px`,
    backgroundColor: `${addTransparency(tokens.colors.font.secondary.value, 0.7)}`,
    padding: '0px',
    margin: '0px'
  };

  // https://github.com/STRML/react-draggable/blob/v4.4.2/lib/DraggableCore.js#L159-L171
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      axis="y"
      position={position}
      onDrag={handleDrag}
      onStop={handleStop}
      bounds="parent"
      cancel=".App-link"
    >
      <div ref={nodeRef} style={{ position: 'absolute', bottom: 0, width: '100%',
          margin: 0,
          borderRadius: `${3*handleHeight}px ${3*handleHeight}px 0 0`,
          backgroundColor: `${addTransparency(tokens.colors.font.secondary.value, 0.7)}`
      }}>
        <div style={{
          width: '60px', height: `${handleHeight}px`,
          backgroundColor: `${tokens.colors.brand.primary[60].value}`,
          borderRadius: `${handleHeight}px`, margin: `${handleHeight}px auto`,
          cursor: 'grab'
        }}></div>
        <div style={contentStyle}>
          <Footer />
        </div>
      </div>
    </Draggable>
  );
}

export default BottomSheet;
