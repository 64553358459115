import {AccountSettings, Card, Flex, Text, useTheme, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Link, useNavigate } from 'react-router-dom';
import {useSession} from "./SessionContext";
import {useEffect, useState} from "react";

const Password = () => {
  const { tokens } = useTheme();
  const { userAttributes } = useSession();
  const [ providerName, setProviderName ] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
        if (userAttributes && userAttributes.identities && userAttributes.identities.length > 0) {
            const identities = JSON.parse(userAttributes.identities)[0];
            setProviderName(identities.providerName);
        }
        else {
            setProviderName('TodoTik');
        }
    }, [userAttributes]
  );

  const handlePasswordSuccess = () => {
    console.log('Password is successfully changed')
    navigate('/profile/');
  }

  return (
    <View style={{ width: '100%', height: '100%',
      overflowY: 'auto',
      padding: `${tokens.space.xs} ${tokens.space.medium} ${tokens.space.xs} ${tokens.space.xs}`,
      backgroundColor: `${tokens.colors.font.secondary}`
    }}>
      <Flex direction="column" textAlign="center" gap="xs">
        <Text fontSize="medium" className="no-print"><Link className="App-link" to="/profile/">&lsaquo; Profile</Link></Text>
        <Text as="h1" fontSize="xl" fontWeight="bold" color="font.tertiary">Change password</Text>
        <Flex direction="column" gap="xs">
            {userAttributes? (
              providerName === 'Cognito'? (
                ''
              ):(
                <>
                <Card variation="outlined" margin="small" backgroundColor="background.warning" borderRadius="xl">
                  <Text color="font.warning">You have signed in with&nbsp;{providerName}.
                    Please change your {providerName}&nbsp;password there.</Text>
                </Card>
                </>
              )
            ):(
              <Card variation="outlined" backgroundColor="brand.primary.80" borderRadius="xl">
                <Text>Please <Link to="/signin/">sign in</Link> to change your password.</Text>
              </Card>
            )
            }
        </Flex>
          <Card backgroundColor="brand.primary.80" variation="elevated" borderRadius="xl">
            <Card backgroundColor="brand.primary.80" textAlign="left" margin="0 xxs large 0" borderRadius="large">
              {providerName === 'Cognito'? (
                ''
              ):(
                <Text marginBottom="small">You can change your TodoTik&nbsp;account password&nbsp;here.</Text>
              )}
              <Text>
                If you have forgotten your current password, please
                sign out and use the forgotten&nbsp;password link on
                the sign-in page.
              </Text>
            </Card>
          <AccountSettings.ChangePassword
              onSuccess={handlePasswordSuccess}/>
        </Card>
      </Flex>
    </View>
  );
}

export default Password;
