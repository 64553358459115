export const addTransparency = (color, alpha) => {
    // Ensure alpha is within 0 and 1
    alpha = Math.max(0, Math.min(1, alpha));

    // Detect and handle color variables
    if (color.startsWith('var')) {
        return `color-mix(in srgb, ${color} ${alpha*100}%, transparent)`;
    }

    // Detect and handle HEX format
    if (color.startsWith('#')) {
        const r = parseInt(color.slice(1, 3), 16),
            g = parseInt(color.slice(3, 5), 16),
            b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    // Detect and handle HSL format
    if (color.startsWith('hsl')) {
        return color.replace(')', `, ${alpha*100}%)`);
    }

    console.warn('Unknown color format', color);
    return color; // Return original color if format is unknown
};