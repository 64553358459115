import '@aws-amplify/ui-react/styles.css';

import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { PhoneNumberForm, VerificationForm } from './ui-components';

import { Card, Flex, Text } from '@aws-amplify/ui-react';
import { updateUserAttribute, confirmUserAttribute } from 'aws-amplify/auth';
import {useSession} from "./SessionContext";

const PhoneNumber = () => {

    const { userAttributes, setUserAttributes } = useSession();

    const [newPhone, setNewPhone] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    async function handleUpdateClick(fields) {
        const updatedPhone = fields['phone_number'].trim();
        if (updatedPhone === userAttributes.phone_number) {
            console.log('Phone number number is not changed')
            navigate('/profile/');
            return;
        }
        setNewPhone(updatedPhone);

        try {
            const output = await updateUserAttribute({
                userAttribute: {
                    attributeKey: 'phone_number',
                    value: updatedPhone
                }
            });
            const { nextStep } = output;
            switch (nextStep.updateAttributeStep) {
                case 'CONFIRM_ATTRIBUTE_WITH_CODE':
                    setErrorMessage('');
                    const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    console.log(
                        `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
                    );
                    // Collect the confirmation code from the user and pass to confirmUserAttribute.
                    setCodeSent(true);
                    break;
                case 'DONE':
                    // Attribute was successfully updated.
                    setUserAttributes({
                        ...userAttributes,
                            phone_number: updatedPhone
                    });
                    navigate('/profile/');
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
        return {'phone_number': updatedPhone};
    }

    const handleCancel = () => {
        console.log('Canceled phone number change')
        navigate('/profile/');
    }

    async function handleVerifyClick(fields) {
        const confirmationCode = fields['code'].trim();

        try {
            console.log('Confirming phone number: ', confirmationCode);
            await confirmUserAttribute({
                userAttributeKey: 'phone_number',
                confirmationCode: confirmationCode
            });
            console.log('PhoneNumber number is successfully changed')
            navigate('/profile/');
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        }
    }
    function renderUpdateForm() {
        return (
            <PhoneNumberForm
                initialData={{
                    phone_number: newPhone? newPhone : (userAttributes? userAttributes.phone_number : '')
                }}
                onCancel={handleCancel}
                onSubmit={handleUpdateClick} />
        );
    }

    function renderConfirmationForm() {
        return (
            <Flex direction="column">
            <VerificationForm
                onChange={() => {
                    setErrorMessage('');
                }}
                onCancel={handleCancel}
                onSubmit={handleVerifyClick}/>
            <Text variation="error" color="font.error">{errorMessage? errorMessage : ''}</Text>
            </Flex>
        );
    }

    return (
        <Card backgroundColor="brand.primary.80" margin="medium" className="ChangePhoneNumber" textAlign="left" borderRadius="40px">
            <Flex direction="column" textAlign="center" gap="xs">
                <Text className="no-print"><Link className="App-link" to="/profile/">&lsaquo; Profile</Link></Text>
                <Text as="h1" fontSize="xl" fontWeight="bold" color="font.secondary">Change phone number</Text>
            </Flex>
            <Flex maxWidth="100%" justifyContent="space-around">
                {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
            </Flex>
        </Card>
    );
}

export default PhoneNumber;