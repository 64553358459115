import React from 'react';

import {
  Card,
  Flex,
  Image,
  Button,
  useAuthenticator,
  TextField, Text, View, useTheme
} from '@aws-amplify/ui-react';
import {AccountSettings} from '@aws-amplify/ui-react';
import {Link, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import {useSession} from './SessionContext';
import VerifyButton from "./VerifyButton";

const Delete = () => {
  const { tokens } = useTheme();
  const { user } = useSession();
  const { userAttributes } = useSession();
  const { authStatus } = useSession();

  const navigate = useNavigate();

  const handleDeleteSuccess = () => {
    console.info('User has been successfully deleted')
    navigate('/');
  }

  return (
    <View
          overflow="hidden auto"
          width="100" height="100%"
          padding="0 0 xs xs"
          backgroundColor="font.secondary" marginTop="zero">
      {authStatus === 'authenticated' && userAttributes? (
        <Flex alignItems="start" marginTop="zero">
          <Flex width="100%" direction="column" gap="xs" marginTop="zero">
            <Card backgroundColor="background.warning" marginBottom="xl" borderRadius="xl xl xl xl">
              <Flex direction="column" width="100%" alignItems="start" justifyContent="space-between">
                <Text className="no-print"><Link className="App-link" to="/profile/">&lsaquo; Profile</Link></Text>
                <TextField label="Name" value={userAttributes.name} width="100%" disabled={true} color="font.secondary" />
                <TextField label="Email" value={userAttributes.email} width="100%" disabled={true} color="font.tertiary" />
                <TextField label="Phone number" value={userAttributes.phone_number} width="100%" disabled={true} />
                <TextField label="Authenticated user identifier (sub)" width="100%" disabled={true} value={userAttributes.sub} />
                <TextField label="Authenticated technical username" width="100%" disabled={true} value={user.username} />
                <div className="no-print" style={{
                  backgroundColor: tokens.colors.background.error,
                  borderRadius: '5px',
                  marginBottom: '112pt', /* Makes Delete visible on mobile browser */
                  paddingBottom: '0pt',
                  height: '42px'
                }}>
                <AccountSettings.DeleteUser
                    onSuccess={handleDeleteSuccess} />
                </div>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      ) : (
        <div style={{ overflow: 'hidden' }}>
          <div style={{
              paddingTop: '30vh', textAlign: 'center'
          }}>
              <Text color="font.active">
              Please <Link to="/signin/">sign in</Link> to request deletion of your personal data.
              </Text>
          </div>
        </div>
      )}
    </View>
  );
}

export default Delete;
