import React, {useEffect, useState} from 'react';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';

import {Card, Loader} from '@aws-amplify/ui-react';

import { Text } from '@aws-amplify/ui-react';
import {useSession} from "./SessionContext";

const SignInLoader = () => {
    const { route } = useSession();
    const [ticks, setTicks] = useState(0);
    const [reportedRoutes, setReportedRoutes] = useState([]);

    useEffect(() => {
        // Set up a timer that ticks every 50 ms
        const interval = setInterval(() => {
            setTicks(ticks => ticks + 1);

            const waitProgress = 100 - (7000/(ticks+10));
            switch (route) {
                case 'idle':
                    setPercentage(Math.min(waitProgress, 35));
                    break;
                case 'signIn':
                    // This actually occurs on sign out, because
                    // Authenticator then routes to signIn.
                    setPercentage(Math.min(waitProgress, 100));
                    break;
                case 'transition':
                    setPercentage(Math.min(10 + waitProgress, 75));
                    break;
                case 'authenticated':
                    setPercentage(Math.min((400 + waitProgress)/500, 100));
                    break;
                case 'setup':
                    // This occurs for example when testing without sign-in session
                    setPercentage(Math.min(waitProgress, 100));
                    break;
                default:
                    if (!reportedRoutes.includes(route)) {
                        console.error('Unexpected authenticator route', route);
                        setReportedRoutes([...reportedRoutes, route]);
                    }
                    break;
            }
        }, 50);

        // Clean up the interval on unmount
        return () => clearInterval(interval);
    });

    const [percentage, setPercentage] = React.useState(0);

    return (<>
        <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.active" margin="xl medium xs medium">
        Signing {route === 'signIn'?
            // This actually occurs on sign out, because Authenticator then routes to signIn.
            'Out' : 'In'}...
        </Text>
        {ticks < 400 ?<>
          <Card variation="elevated" backgroundColor="brand.primary.90" maxWidth="300pt" margin="xs xl"
              padding="large" borderRadius="xl">
            {route === 'signIn' || ticks <= 60? // Determinate loader only for sign-in
                <Loader variation="linear"/>
                :
                <Loader variation="linear" isDeterminate isPercentageTextHidden percentage={percentage}/>
            }
            {ticks > 60 ?
            <Text as="p" color="font.primary" margin="small medium">
              This should only take a few ({Math.round(ticks / 20)}) seconds...
            </Text>
            : ''}
          </Card>
        </>:<>
            <Card variation="elevated" backgroundColor="background.error" maxWidth="300pt" margin="xs xl"
                  padding="large" borderRadius="xl">
                <Text as="p" color="font.error" margin="small medium">
                    Something must have gone wrong. Please try&nbsp;again later.
                </Text>
            </Card>
        </>}
    </>);
}

export default SignInLoader;
