import React, {useCallback, useEffect} from 'react';

import {
  Card,
  Flex,
  Loader,
  Image,
  Button,
  useAuthenticator,
  TextField, Text, View, useTheme
} from '@aws-amplify/ui-react';
import {Link, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { useSession } from './SessionContext';
import {existingAccounts, findAccountStatusesByAccountId} from "./utils/accounts";
import {listFederatedIdsByUsername} from "./utils/federatedIds";
import {listAccountEmailsByAccount} from "./utils/accountEmails";
import {listAccountPhonesByAccount} from "./utils/accountPhones";

const ID = () => {
  const { tokens } = useTheme();

  const { signOut } = useAuthenticator((context) => [context.user]);
  const { user, userAttributes } = useSession();
  const { accessToken, idToken } = useSession();
  const { accounts, setAccounts } = useSession();
  const { accountStatus, setAccountStatus } = useSession();
  const { federatedIds, setFederatedIds } = useSession();
  const { accountEmails, setAccountEmails } = useSession();
  const { accountPhones, setAccountPhones } = useSession();

  const navigate = useNavigate();

  const checkAccount = useCallback(async () => {
        try {
            console.log("Checking accounts");
            if (user?.username !== user?.userId) {
                // For federated users, the Cognito user ID is not the same as the username.
                if (!federatedIds) {
                    await listFederatedIdsByUsername(user.username).then(setFederatedIds);
                }
            }
            if (userAttributes && userAttributes.email_verified && userAttributes.sub) {
                if (!accounts) {
                    const accounts = (await existingAccounts(user, userAttributes)).matchedAccounts;
                    setAccounts(accounts);
                    Promise.all(accounts.map(a => findAccountStatusesByAccountId(a.id)))
                        .then(results => results.flat().filter(Boolean))
                        .then(setAccountStatus);
                }
            }
            if (accounts?.length > 0) {
                if (!accountEmails) {
                    Promise.all(accounts.map(a => listAccountEmailsByAccount(a.id)))
                        .then(results => results.flat().filter(Boolean))
                        .then(setAccountEmails);
                }
                if (!accountPhones) {
                    Promise.all(accounts.map(a => listAccountPhonesByAccount(a.id)))
                        .then(results => results.flat().filter(Boolean))
                        .then(setAccountPhones);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [userAttributes]);

    useEffect(() => {
        checkAccount().then();
    }, [checkAccount]);

    return (
      <View style={{
          width: '100%', height: '100%',
          overflowY: 'auto',
          padding: `${tokens.space.xs} ${tokens.space.medium} ${tokens.space.xxxl} ${tokens.space.medium}`,
          backgroundColor: `${tokens.colors.font.secondary}`
      }}>
          <Text as="h1">Technical account information</Text>
          <pre>User: {JSON.stringify(user, null, 2)}</pre>
          <pre>UserAttributes: {JSON.stringify(userAttributes, null, 2)}</pre>
          <pre>Access token: {accessToken? JSON.stringify(accessToken, null, 2) : 'MISSING'}</pre>
          <pre>ID token: {idToken? JSON.stringify(idToken, null, 2) : 'MISSING'}</pre>
          {!accessToken || !idToken?
              <Card backgroundColor="background.error" color="font.error">
              <Text as="p">Missing tokens indicate a problem with the <code>PreTokenGeneration</code> Lambda function.</Text>
              </Card>
              : <></>
          }
          <pre>Account(s): {JSON.stringify(accounts, null, 2)}</pre>
          <pre>AccountStatus(es): {JSON.stringify(accountStatus, null, 2)}</pre>
          <pre>FederatedId(s): {JSON.stringify(federatedIds, null, 2)}</pre>
          <pre>AccountPhone(s): {JSON.stringify(accountPhones, null, 2)}</pre>

      </View>
  );
}

export default ID;
