import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Flex, Text, useAuthenticator} from '@aws-amplify/ui-react';
import { useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useSession } from './SessionContext';


const Session = () => {
  const { tokens } = useTheme();
  const { user } = useSession();
  const { userAttributes } = useSession();
  const initials = userAttributes?.name ?
      userAttributes.name.match(/\b(\w)/g).join('').toUpperCase() : "";

  return (
      <div className="no-print" style={{ textAlign: 'right', color: 'white', pointerEvents: 'auto' }}>
        {userAttributes ? (
            <Link to={`/profile/`}>
              <div style={{
                boxShadow: '0px 0px 4px ' + tokens.colors.brand.secondary[60].value,
                margin: '0px',
                paddingTop: '7px',
                paddingRight: (3-initials.length)*8 + 'px',
                width: '40px',
                height: '40px',
                overflow: 'hidden',
                lineHeight: `${tokens.lineHeights.medium}`,
                backgroundColor: `${tokens.colors.brand.primary[80].value}`,
                borderRadius: `${tokens.radii.xl.value}`,
              }}>
                  <Text color="brand.primary.10">{initials}</Text>
              </div>
            </Link>
        ) : (
            <Flex alignItems="center">
              <Link to="/signin/">
                <Text fontSize="large" className="App-link">Sign&nbsp;in</Text>
              </Link>
            </Flex>
        )}
      </div>
  );
}

export default Session;