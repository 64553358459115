import {generateClient} from "aws-amplify/api";
import {createAccountEmail} from "../graphql/mutations";
import {getAccountEmail, listAccountEmails} from "../graphql/queries";

const client = generateClient({authMode: 'userPool'});

export async function newAccountEmail(accountId, email, label, verifiedAt = null) {
    const response = await client.graphql({
        mutation: createAccountEmail,
        variables: {
            accountId,
            email,
            label,
            verifiedAt,
            detachedAt: null,
            redactedAt: null
        }
    });
    return response.data.createAccountEmail;
}

export async function getAccountEmailByAccount(accountId) {
    const response = await client.graphql({
        query: getAccountEmail,
        variables: { accountId: accountId }
    });
    return response.data.getAccountEmail;
}

export async function listAccountEmailsByAccount(accountId) {
    const response = await client.graphql({
        query: listAccountEmails,
        variables: {accountId: accountId}
    });
    return response.data.listAccountEmails.items;
}

export async function listAccountEmailByEmail(email) {
    const response = await client.graphql({
        query: listAccountEmails,
        variables: {email}
    });
    return response.data.listAccountEmails.items;
}