import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { fetchUserAttributes } from 'aws-amplify/auth';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Hub} from "aws-amplify/utils";

const SessionContext = createContext(null);

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
    const { authStatus, route } = useAuthenticator(context => [context.authStatus, context.route]);

    const [accessToken, setAccessToken] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [user, setUser] = useState(null);
    const [userAttributes, setUserAttributes] = useState(null);
    const [location, setLocation] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [federatedIds, setFederatedIds] = useState(null);
    const [accountEmails, setAccountEmails] = useState(null);
    const [accountPhones, setAccountPhones] = useState(null);
    const [accountStatus, setAccountStatus] = useState({});
    const [customState, setCustomState] = useState(null);

    useEffect(() => {
        console.debug('Listening for auth events');
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
                case "signedIn":
                    console.info("Sign-in");
                    checkUser().then();
                    break;
                case "signedOut":
                    console.info("Sign-out");
                    clearSession().then();
                    break;
                case "signInWithRedirect":
                    console.info("Redirect sign-in");
                    break;
                case "signInWithRedirect_failure":
                    console.error("Redirect sign-in error", payload.data);
                    break;
                case "customOAuthState":
                    console.info("Received OAuth state");
                    setCustomState(payload.data);
                    break;
                default:
                    console.debug("Received", payload);
                    break;
            }
        });
        checkUser().then();
        return unsubscribe;
    }, []);

    const clearSession = async() => {
        if (authStatus !== 'authenticated') {
            setUser(null);
            setUserAttributes(null);
            setIdToken(null);
            setAccessToken(null);
            setAccounts(null);
            setFederatedIds(null);
            setAccountEmails(null);
            setAccountPhones(null);
            setAccountStatus(null);
            console.debug('Cleared session context');
        }
        else {
            console.warn(`auth status ${authStatus}, route ${route}, not clearing session`);
        }
    };

    const checkUser = useCallback(async () => {
        console.debug('Checking session user');
        try {
            const currentUser = await getCurrentUser();
            if (currentUser) {
                if (route === "signOut") {
                    console.debug('Signing out, not updating user data');
                    return user;
                }
                console.debug("Current user", currentUser);
                if (JSON.stringify(currentUser) === JSON.stringify(user)) {
                    console.warn('User data unchanged, not updating');
                    return user;
                }
                setUser(currentUser);

                try {
                    const authSession = await fetchAuthSession();
                    if (authSession) {
                        console.debug("Checking auth session tokens");
                        const { accessToken, idToken } = authSession?.tokens ?? {};
                        if (accessToken) {
                            setAccessToken(accessToken);
                        }
                        if (idToken) {
                            setIdToken(idToken);
                        }
                    }
                    else {
                        console.warn("No auth session");
                    }
                } catch (err) {
                    console.error(err);
                }

                const attributes = await fetchUserAttributes();
                console.debug("User attributes", attributes);
                if (attributes) {
                    setUserAttributes(attributes);
                } else {
                    console.log("Cleared user attributes", attributes);
                    setUserAttributes(null);
                }
            }
            else {
                console.debug('No current user');
            }
            return user;
        } catch (error) {
            if (error.name === 'UserUnAuthenticatedException') {
            }
            else {
                console.error(error);
            }
        }
        return user;
    }, [authStatus, route]);

    const fetchLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude, accuracy } = position.coords;
                    console.debug('Got location: ', latitude, longitude, accuracy);
                    setLocation({ latitude, longitude, accuracy });
                },
                (error) => {
                    console.error('Error getting location: ', error);
                    setLocation(null);
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
            setLocation(null);
        }
    };

    const contextValue = {
        accessToken,
        idToken,
        accounts,
        setAccounts,
        user,
        userAttributes,
        setUserAttributes,
        location,
        fetchLocation,
        federatedIds,
        setFederatedIds,
        accountEmails,
        setAccountEmails,
        accountPhones,
        setAccountPhones,
        accountStatus,
        setAccountStatus,
        authStatus,
        route
    };

    return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
