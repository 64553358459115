/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function LogoWithText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      variantValues: { color: "neutral" },
      overrides: {
        LogoText_T38621489: {},
        LogoText_o38621490: {},
        LogoText_d: {},
        LogoText_o38621492: {},
        LogoText_T38621493: {},
        LogoText_i: {},
        LogoText_k: {},
        LogoText: {},
        LogoChevron: {},
        LogoParallelogram: {},
        LogoCheckmark: {},
        Logo: {},
        Group: {},
        "TodoTik logo with text": {},
        LogoWithText: {},
      },
    },
    {
      variantValues: { color: "brand" },
      overrides: {
        LogoText_T38621489: {
          paths: [
            {
              d: "M0.445653 3.4766C0.335928 3.29851 0.234644 3.05543 0.140956 2.74735C0.0472674 2.43928 0 2.11516 0 1.77502C0 1.14283 0.136736 0.68958 0.410205 0.413579C0.683674 0.138422 1.03901 0 1.47707 0L11.7009 0C11.8098 0.178937 11.9119 0.42202 12.0056 0.729251C12.0993 1.03733 12.1466 1.36144 12.1466 1.70159C12.1466 2.33377 12.0098 2.78786 11.7364 3.06302C11.4629 3.33902 11.1067 3.4766 10.6695 3.4766L8.06649 3.4766L8.06649 14.7074C7.89431 14.7564 7.62421 14.8045 7.25706 14.8534C6.8899 14.9024 6.52612 14.926 6.16656 14.926C5.807 14.926 5.49048 14.8973 5.21701 14.8408C4.94354 14.7842 4.71312 14.6829 4.5249 14.5369C4.33668 14.3909 4.19657 14.1883 4.10288 13.9292C4.00919 13.6701 3.96192 13.3299 3.96192 12.9079L3.96192 3.4766L0.445653 3.4766Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_o38621490: {
          paths: [
            {
              d: "M12.2875 6.27037C12.2875 7.27563 12.139 8.17031 11.8419 8.95611C11.5448 9.74191 11.127 10.4028 10.5876 10.9371C10.0483 11.4714 9.40345 11.8773 8.6531 12.1525C7.90275 12.4276 7.06631 12.5661 6.14377 12.5661C5.22124 12.5661 4.38479 12.4201 3.63444 12.1289C2.88408 11.8377 2.23924 11.4199 1.6999 10.8771C1.16056 10.3344 0.741914 9.67354 0.445656 8.89618C0.148554 8.11798 0 7.24355 0 6.27122C0 5.31492 0.148554 4.44809 0.445656 3.66989C0.742758 2.89168 1.16056 2.23164 1.6999 1.68893C2.23924 1.14621 2.88408 0.728407 3.63444 0.437213C4.38479 0.14602 5.22124 0 6.14377 0C7.06631 0 7.90275 0.150239 8.6531 0.449874C9.40345 0.750352 10.0483 1.17153 10.5876 1.71425C11.127 2.25781 11.5448 2.91785 11.8419 3.69521C12.139 4.47172 12.2875 5.33096 12.2875 6.27037ZM4.08009 6.27037C4.08009 7.25875 4.26325 8.01669 4.63125 8.54337C4.99841 9.07006 5.51074 9.3334 6.1674 9.3334C6.82407 9.3334 7.32796 9.06584 7.67992 8.53156C8.03189 7.99644 8.20745 7.24355 8.20745 6.27122C8.20745 5.29888 8.02767 4.54938 7.66811 4.0227C7.30855 3.49601 6.80043 3.23267 6.14377 3.23267C5.48711 3.23267 4.979 3.49601 4.61944 4.0227C4.25903 4.54853 4.08009 5.29804 4.08009 6.27037Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_d: {
          paths: [
            {
              d: "M0 10.1606C0 9.09116 0.156147 8.16356 0.469286 7.37691C0.781581 6.59111 1.21542 5.93867 1.7708 5.42043C2.32533 4.90219 2.9744 4.5173 3.71716 4.26578C4.45907 4.0151 5.2609 3.88934 6.12098 3.88934C6.43328 3.88934 6.73882 3.91803 7.03592 3.97458C7.33303 4.03114 7.57526 4.10035 7.76264 4.18137L7.76264 0.243083C7.91879 0.194129 8.16862 0.141799 8.51299 0.0852482C8.85652 0.0286975 9.20848 0 9.56804 0C9.91157 0 10.2205 0.0244772 10.494 0.0725875C10.7674 0.121542 10.9978 0.218606 11.1861 0.364625C11.3743 0.510644 11.5144 0.708993 11.6081 0.960517C11.7018 1.21204 11.749 1.54037 11.749 1.94467L11.749 13.4667C11.749 14.2288 11.4047 14.8365 10.7176 15.2898C10.2635 15.5979 9.67777 15.8696 8.95864 16.1043C8.23952 16.3389 7.41067 16.4571 6.47294 16.4571C5.45672 16.4571 4.546 16.3111 3.74079 16.0199C2.93557 15.7278 2.25528 15.3067 1.70074 14.7555C1.14536 14.2044 0.723345 13.5443 0.434683 12.7745C0.144333 12.0039 0 11.1329 0 10.1606ZM6.65948 7.12201C5.81544 7.12201 5.16637 7.37353 4.71312 7.87574C4.25987 8.37794 4.03283 9.14011 4.03283 10.1606C4.03283 11.1658 4.24384 11.9229 4.66586 12.4336C5.08788 12.9442 5.69727 13.1991 6.49489 13.1991C6.77596 13.1991 7.03001 13.1544 7.25706 13.0657C7.48326 12.9771 7.65967 12.8758 7.78459 12.7619L7.78459 7.41405C7.47229 7.21908 7.09669 7.12201 6.65948 7.12201Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_o38621492: {
          paths: [
            {
              d: "M12.2884 6.27037C12.2884 7.27563 12.1398 8.17031 11.8427 8.95611C11.5456 9.74191 11.1278 10.4028 10.5885 10.9371C10.0491 11.4722 9.40429 11.8773 8.65394 12.1525C7.90359 12.4276 7.06715 12.5661 6.14461 12.5661C5.22208 12.5661 4.38563 12.4201 3.63528 12.1289C2.88492 11.8377 2.24008 11.4199 1.70074 10.8771C1.1614 10.3344 0.742753 9.67354 0.445651 8.89618C0.148548 8.11798 0 7.24355 0 6.27122C0 5.31492 0.148548 4.44809 0.445651 3.66989C0.742753 2.89168 1.16055 2.23164 1.70074 1.68893C2.24008 1.14621 2.88492 0.728407 3.63528 0.437213C4.38563 0.14602 5.22208 0 6.14461 0C7.06715 0 7.90359 0.150239 8.65394 0.449874C9.40429 0.750352 10.0491 1.17153 10.5885 1.71425C11.1278 2.25781 11.5456 2.91785 11.8427 3.69521C12.1398 4.47172 12.2884 5.33096 12.2884 6.27037ZM4.08093 6.27037C4.08093 7.25875 4.26493 8.01669 4.63209 8.54337C4.99925 9.07006 5.51158 9.3334 6.16824 9.3334C6.82491 9.3334 7.3288 9.06584 7.68077 8.53156C8.03273 7.99644 8.20829 7.24355 8.20829 6.27122C8.20829 5.29888 8.02851 4.54938 7.66895 4.0227C7.30939 3.49601 6.80128 3.23267 6.14461 3.23267C5.48795 3.23267 4.97984 3.49601 4.62028 4.0227C4.25987 4.54853 4.08093 5.29804 4.08093 6.27037Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_T38621493: {
          paths: [
            {
              d: "M0.445656 3.4766C0.33593 3.29851 0.234637 3.05543 0.140948 2.74735C0.0472597 2.43928 0 2.11516 0 1.77502C0 1.14283 0.136733 0.68958 0.410202 0.413579C0.683671 0.138422 1.03901 0 1.47707 0L11.7009 0C11.8098 0.178937 11.9119 0.42202 12.0056 0.729251C12.0993 1.03733 12.1466 1.36144 12.1466 1.70159C12.1466 2.33377 12.0098 2.78786 11.7364 3.06302C11.4629 3.33902 11.1067 3.4766 10.6695 3.4766L8.06649 3.4766L8.06649 14.7074C7.8943 14.7564 7.62421 14.8045 7.25706 14.8534C6.8899 14.9024 6.52611 14.926 6.16655 14.926C5.80699 14.926 5.49048 14.8973 5.21701 14.8408C4.94354 14.7842 4.71312 14.6829 4.5249 14.5369C4.33668 14.3909 4.19657 14.1883 4.10288 13.9292C4.00919 13.6701 3.96192 13.3299 3.96192 12.9079L3.96192 3.4766L0.445656 3.4766Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_i: {
          paths: [
            {
              d: "M0 2.18775C0 1.57245 0.1992 1.05336 0.597587 0.632186C0.995974 0.21101 1.5235 0 2.18016 0C2.83683 0 3.36435 0.21101 3.76274 0.632186C4.16112 1.05336 4.36031 1.57245 4.36031 2.18775C4.36031 2.8039 4.16112 3.32214 3.76274 3.74332C3.36435 4.16534 2.83683 4.3755 2.18016 4.3755C1.5235 4.3755 0.995974 4.16534 0.597587 3.74332C0.1992 3.32214 0 2.8039 0 2.18775ZM4.17378 17.2589C4.0016 17.291 3.74754 17.3357 3.41161 17.3923C3.07569 17.4488 2.73554 17.4775 2.39117 17.4775C2.0468 17.4775 1.73872 17.4531 1.46525 17.405C1.19178 17.356 0.961365 17.2589 0.773144 17.1129C0.584923 16.9669 0.440591 16.7685 0.339306 16.517C0.237177 16.2663 0.187385 15.938 0.187385 15.5329L0.187385 5.63903C0.359569 5.60696 0.612773 5.56223 0.949545 5.50567C1.28547 5.44912 1.62563 5.42043 1.97 5.42043C2.31352 5.42043 2.62244 5.4449 2.89591 5.49301C3.16938 5.54197 3.39979 5.63903 3.58802 5.78505C3.77624 5.93107 3.91972 6.12942 4.02185 6.38094C4.12314 6.63247 4.17378 6.9608 4.17378 7.3651L4.17378 17.2589Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText_k: {
          paths: [
            {
              d: "M11.0932 13.9283C11.0147 14.6905 10.8003 15.2738 10.4484 15.6789C10.0964 16.084 9.58408 16.2866 8.91223 16.2866C8.41171 16.2866 7.96605 16.1608 7.57526 15.9102C7.18447 15.6595 6.76245 15.2341 6.3092 14.634L3.98724 11.6199L3.98724 16.0444C3.81506 16.0764 3.56101 16.1212 3.22508 16.1777C2.88831 16.2343 2.549 16.263 2.20463 16.263C1.86026 16.263 1.55135 16.2385 1.27872 16.1904C1.00525 16.1414 0.774835 16.0444 0.586614 15.8983C0.398393 15.7523 0.254061 15.554 0.152776 15.3024C0.0506474 15.0518 0 14.7234 0 14.3183L0 0.243083C0.172184 0.194129 0.425399 0.141799 0.762171 0.0852482C1.0981 0.0286975 1.43824 0 1.78261 0C2.12614 0 2.43506 0.0244772 2.70853 0.0725875C2.982 0.121542 3.21242 0.218606 3.40064 0.364625C3.58886 0.510644 3.73235 0.708993 3.83448 0.960517C3.93576 1.21204 3.98724 1.54037 3.98724 1.94467L3.98724 8.36191L8.02092 4.08347C8.83373 4.08347 9.48702 4.27 9.9791 4.64222C10.4712 5.01529 10.7176 5.48542 10.7176 6.05177C10.7176 6.29485 10.6788 6.51768 10.6003 6.72025C10.5218 6.92282 10.4011 7.12961 10.2365 7.33977C10.0719 7.55078 9.86092 7.77361 9.60349 8.00825C9.34521 8.24374 9.04474 8.51468 8.70037 8.82275L7.34062 10.0382L11.0932 13.9283Z",
              fill: "rgba(48,64,80,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoText: {},
        LogoChevron: {
          paths: [
            {
              d: "M18.8645 1.72996C18.8645 2.13004 18.8493 2.53096 18.8687 2.93019C18.8873 3.29988 18.7134 3.51511 18.407 3.69067C17.6212 4.14054 16.8219 4.57438 16.0733 5.0808C14.4755 6.16202 13.152 7.52852 12.0236 9.09421C11.063 10.4261 10.3642 11.8804 9.86197 13.4368C9.83918 13.5069 9.81893 13.5778 9.78854 13.6444C9.71258 13.8124 9.59779 13.9263 9.39691 13.9221C9.18843 13.9179 9.12006 13.7668 9.07533 13.6065C8.82971 12.7236 8.51657 11.866 8.0878 11.0549C6.64365 8.32107 4.62217 6.12657 1.96682 4.52796C1.49838 4.24605 1.02318 3.97511 0.540394 3.72105C0.170704 3.52608 -0.0158287 3.26865 0.00105212 2.82721C0.0297495 2.06842 0.0069602 1.30879 0.00611616 0.549151C0.00611616 -0.0146682 0.196869 -0.128614 0.697385 0.144011C2.48169 1.11719 4.26514 2.09206 6.0486 3.06692C7.00827 3.59192 7.96963 4.11353 8.92424 4.64697C9.29815 4.85544 9.64505 4.83266 10.0122 4.63009C12.7224 3.1412 15.4352 1.65822 18.1479 0.174397C18.721 -0.138742 18.8662 -0.0501179 18.8653 0.603169C18.8645 0.978767 18.8653 1.35436 18.8653 1.72996L18.8645 1.72996Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoParallelogram: {
          paths: [
            {
              d: "M7.1489 1.76947C7.1489 1.32889 7.15143 0.887451 7.14805 0.446862C7.14468 0.0594475 6.88471 -0.111049 6.54372 0.0763284C5.38148 0.714423 4.22599 1.36433 3.06881 2.01171C2.15049 2.52067 1.23386 3.03385 0.317236 3.54618C0.10707 3.6635 0.00409808 3.84834 0.00409808 4.08974C0.00325404 4.96332 -0.00518682 5.8369 0.00494166 6.71048C0.0100059 7.13082 0.279255 7.2819 0.647256 7.07849C1.61115 6.54505 2.57167 6.0074 3.53219 5.46975C4.62522 4.86373 5.71487 4.25264 6.80537 3.64324C7.03917 3.51242 7.15565 3.3242 7.14552 3.04397C7.13033 2.62027 7.1413 2.19487 7.1413 1.77032L7.1489 1.77032L7.1489 1.76947Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoCheckmark: {
          paths: [
            {
              d: "M10.8528 1.79591L10.8519 1.79591C10.8519 1.33928 10.8561 0.881813 10.8502 0.425188C10.8452 0.0445255 10.5903 -0.105714 10.2619 0.0782871C9.09632 0.731574 7.93323 1.39077 6.76593 2.04237C6.76255 2.04406 6.76086 2.04574 6.75749 2.04743C6.7406 2.05419 6.72288 2.06094 6.70516 2.06938C6.66042 2.08963 6.61822 2.11411 6.57517 2.13775C5.81976 2.55808 5.05759 2.96828 4.31061 3.4038C3.85736 3.66799 3.44716 3.68571 2.98294 3.40549C2.21486 2.94212 1.42315 2.51672 0.632287 2.09217C0.235588 1.87947 0.00432067 2.03477 0.00432067 2.48465C0.00347663 3.33375 0.0119168 4.1837 0.000100216 5.0328C-0.00411998 5.33413 0.125019 5.52825 0.374011 5.66499C1.3109 6.17985 2.24778 6.69387 3.18382 7.21043C3.49527 7.38261 3.80588 7.39021 4.11733 7.21887C5.06941 6.69725 6.01979 6.1731 6.97018 5.65064C7.00057 5.63376 7.02758 5.61603 7.0529 5.59662C7.08666 5.58227 7.12127 5.56624 7.15756 5.54682C7.38714 5.42359 7.60997 5.28602 7.83701 5.15857C8.69793 4.67746 9.55886 4.19467 10.4223 3.71779C10.7228 3.55236 10.8781 3.327 10.857 2.96828C10.8359 2.58002 10.8528 2.18754 10.8528 1.79591Z",
              fill: "rgba(76,196,220,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Logo: {},
        Group: {},
        "TodoTik logo with text": {},
        LogoWithText: {},
      },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="127.88px"
      height="26px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "LogoWithText")}
      {...rest}
    >
      <View
        width="128px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="-3px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "TodoTik logo with text")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="108.67px"
          height="22.94px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="19.29%"
          bottom="9.02%"
          left="7.73%"
          right="7.38%"
          {...getOverrideProps(overrides, "Group")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="83.15px"
            height="17.67px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="22.97%"
            left="23.48%"
            right="0%"
            {...getOverrideProps(overrides, "LogoText")}
          >
            <Icon
              width="12.15px"
              height="14.93px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 12.14657974243164,
                height: 14.926006317138672,
              }}
              paths={[
                {
                  d: "M0.445653 3.4766C0.335928 3.29851 0.234644 3.05543 0.140956 2.74735C0.0472674 2.43928 0 2.11516 0 1.77502C0 1.14283 0.136736 0.68958 0.410205 0.413579C0.683674 0.138422 1.03901 0 1.47707 0L11.7009 0C11.8098 0.178937 11.9119 0.42202 12.0056 0.729251C12.0993 1.03733 12.1466 1.36144 12.1466 1.70159C12.1466 2.33377 12.0098 2.78786 11.7364 3.06302C11.4629 3.33902 11.1067 3.4766 10.6695 3.4766L8.06649 3.4766L8.06649 14.7074C7.89431 14.7564 7.62421 14.8045 7.25706 14.8534C6.8899 14.9024 6.52612 14.926 6.16656 14.926C5.807 14.926 5.49048 14.8973 5.21701 14.8408C4.94354 14.7842 4.71312 14.6829 4.5249 14.5369C4.33668 14.3909 4.19657 14.1883 4.10288 13.9292C4.00919 13.6701 3.96192 13.3299 3.96192 12.9079L3.96192 3.4766L0.445653 3.4766Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="14.03%"
              bottom="1.51%"
              left="0%"
              right="85.39%"
              {...getOverrideProps(overrides, "LogoText_T38621489")}
            ></Icon>
            <Icon
              width="12.29px"
              height="12.57px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 12.287537574768066,
                height: 12.566070556640625,
              }}
              paths={[
                {
                  d: "M12.2875 6.27037C12.2875 7.27563 12.139 8.17031 11.8419 8.95611C11.5448 9.74191 11.127 10.4028 10.5876 10.9371C10.0483 11.4714 9.40345 11.8773 8.6531 12.1525C7.90275 12.4276 7.06631 12.5661 6.14377 12.5661C5.22124 12.5661 4.38479 12.4201 3.63444 12.1289C2.88408 11.8377 2.23924 11.4199 1.6999 10.8771C1.16056 10.3344 0.741914 9.67354 0.445656 8.89618C0.148554 8.11798 0 7.24355 0 6.27122C0 5.31492 0.148554 4.44809 0.445656 3.66989C0.742758 2.89168 1.16056 2.23164 1.6999 1.68893C2.23924 1.14621 2.88408 0.728407 3.63444 0.437213C4.38479 0.14602 5.22124 0 6.14377 0C7.06631 0 7.90275 0.150239 8.6531 0.449874C9.40345 0.750352 10.0483 1.17153 10.5876 1.71425C11.127 2.25781 11.5448 2.91785 11.8419 3.69521C12.139 4.47172 12.2875 5.33096 12.2875 6.27037ZM4.08009 6.27037C4.08009 7.25875 4.26325 8.01669 4.63125 8.54337C4.99841 9.07006 5.51074 9.3334 6.1674 9.3334C6.82407 9.3334 7.32796 9.06584 7.67992 8.53156C8.03189 7.99644 8.20745 7.24355 8.20745 6.27122C8.20745 5.29888 8.02767 4.54938 7.66811 4.0227C7.30855 3.49601 6.80043 3.23267 6.14377 3.23267C5.48711 3.23267 4.979 3.49601 4.61944 4.0227C4.25903 4.54853 4.08009 5.29804 4.08009 6.27037Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="28.89%"
              bottom="0%"
              left="14.35%"
              right="70.87%"
              {...getOverrideProps(overrides, "LogoText_o38621490")}
            ></Icon>
            <Icon
              width="11.75px"
              height="16.46px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 11.749040603637695,
                height: 16.457096099853516,
              }}
              paths={[
                {
                  d: "M0 10.1606C0 9.09116 0.156147 8.16356 0.469286 7.37691C0.781581 6.59111 1.21542 5.93867 1.7708 5.42043C2.32533 4.90219 2.9744 4.5173 3.71716 4.26578C4.45907 4.0151 5.2609 3.88934 6.12098 3.88934C6.43328 3.88934 6.73882 3.91803 7.03592 3.97458C7.33303 4.03114 7.57526 4.10035 7.76264 4.18137L7.76264 0.243083C7.91879 0.194129 8.16862 0.141799 8.51299 0.0852482C8.85652 0.0286975 9.20848 0 9.56804 0C9.91157 0 10.2205 0.0244772 10.494 0.0725875C10.7674 0.121542 10.9978 0.218606 11.1861 0.364625C11.3743 0.510644 11.5144 0.708993 11.6081 0.960517C11.7018 1.21204 11.749 1.54037 11.749 1.94467L11.749 13.4667C11.749 14.2288 11.4047 14.8365 10.7176 15.2898C10.2635 15.5979 9.67777 15.8696 8.95864 16.1043C8.23952 16.3389 7.41067 16.4571 6.47294 16.4571C5.45672 16.4571 4.546 16.3111 3.74079 16.0199C2.93557 15.7278 2.25528 15.3067 1.70074 14.7555C1.14536 14.2044 0.723345 13.5443 0.434683 12.7745C0.144333 12.0039 0 11.1329 0 10.1606ZM6.65948 7.12201C5.81544 7.12201 5.16637 7.37353 4.71312 7.87574C4.25987 8.37794 4.03283 9.14011 4.03283 10.1606C4.03283 11.1658 4.24384 11.9229 4.66586 12.4336C5.08788 12.9442 5.69727 13.1991 6.49489 13.1991C6.77596 13.1991 7.03001 13.1544 7.25706 13.0657C7.48326 12.9771 7.65967 12.8758 7.78459 12.7619L7.78459 7.41405C7.47229 7.21908 7.09669 7.12201 6.65948 7.12201Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="6.88%"
              bottom="0%"
              left="30.88%"
              right="54.99%"
              {...getOverrideProps(overrides, "LogoText_d")}
            ></Icon>
            <Icon
              width="12.29px"
              height="12.57px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 12.288383483886719,
                height: 12.566070556640625,
              }}
              paths={[
                {
                  d: "M12.2884 6.27037C12.2884 7.27563 12.1398 8.17031 11.8427 8.95611C11.5456 9.74191 11.1278 10.4028 10.5885 10.9371C10.0491 11.4722 9.40429 11.8773 8.65394 12.1525C7.90359 12.4276 7.06715 12.5661 6.14461 12.5661C5.22208 12.5661 4.38563 12.4201 3.63528 12.1289C2.88492 11.8377 2.24008 11.4199 1.70074 10.8771C1.1614 10.3344 0.742753 9.67354 0.445651 8.89618C0.148548 8.11798 0 7.24355 0 6.27122C0 5.31492 0.148548 4.44809 0.445651 3.66989C0.742753 2.89168 1.16055 2.23164 1.70074 1.68893C2.24008 1.14621 2.88492 0.728407 3.63528 0.437213C4.38563 0.14602 5.22208 0 6.14461 0C7.06715 0 7.90359 0.150239 8.65394 0.449874C9.40429 0.750352 10.0491 1.17153 10.5885 1.71425C11.1278 2.25781 11.5456 2.91785 11.8427 3.69521C12.1398 4.47172 12.2884 5.33096 12.2884 6.27037ZM4.08093 6.27037C4.08093 7.25875 4.26493 8.01669 4.63209 8.54337C4.99925 9.07006 5.51158 9.3334 6.16824 9.3334C6.82491 9.3334 7.3288 9.06584 7.68077 8.53156C8.03273 7.99644 8.20829 7.24355 8.20829 6.27122C8.20829 5.29888 8.02851 4.54938 7.66895 4.0227C7.30939 3.49601 6.80128 3.23267 6.14461 3.23267C5.48795 3.23267 4.97984 3.49601 4.62028 4.0227C4.25987 4.54853 4.08093 5.29804 4.08093 6.27037Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="28.89%"
              bottom="0%"
              left="47.4%"
              right="37.82%"
              {...getOverrideProps(overrides, "LogoText_o38621492")}
            ></Icon>
            <Icon
              width="12.15px"
              height="14.93px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 12.146584510803223,
                height: 14.926006317138672,
              }}
              paths={[
                {
                  d: "M0.445656 3.4766C0.33593 3.29851 0.234637 3.05543 0.140948 2.74735C0.0472597 2.43928 0 2.11516 0 1.77502C0 1.14283 0.136733 0.68958 0.410202 0.413579C0.683671 0.138422 1.03901 0 1.47707 0L11.7009 0C11.8098 0.178937 11.9119 0.42202 12.0056 0.729251C12.0993 1.03733 12.1466 1.36144 12.1466 1.70159C12.1466 2.33377 12.0098 2.78786 11.7364 3.06302C11.4629 3.33902 11.1067 3.4766 10.6695 3.4766L8.06649 3.4766L8.06649 14.7074C7.8943 14.7564 7.62421 14.8045 7.25706 14.8534C6.8899 14.9024 6.52611 14.926 6.16655 14.926C5.80699 14.926 5.49048 14.8973 5.21701 14.8408C4.94354 14.7842 4.71312 14.6829 4.5249 14.5369C4.33668 14.3909 4.19657 14.1883 4.10288 13.9292C4.00919 13.6701 3.96192 13.3299 3.96192 12.9079L3.96192 3.4766L0.445656 3.4766Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="14.03%"
              bottom="1.51%"
              left="62.15%"
              right="23.24%"
              {...getOverrideProps(overrides, "LogoText_T38621493")}
            ></Icon>
            <Icon
              width="4.36px"
              height="17.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 4.36031436920166,
                height: 17.477540969848633,
              }}
              paths={[
                {
                  d: "M0 2.18775C0 1.57245 0.1992 1.05336 0.597587 0.632186C0.995974 0.21101 1.5235 0 2.18016 0C2.83683 0 3.36435 0.21101 3.76274 0.632186C4.16112 1.05336 4.36031 1.57245 4.36031 2.18775C4.36031 2.8039 4.16112 3.32214 3.76274 3.74332C3.36435 4.16534 2.83683 4.3755 2.18016 4.3755C1.5235 4.3755 0.995974 4.16534 0.597587 3.74332C0.1992 3.32214 0 2.8039 0 2.18775ZM4.17378 17.2589C4.0016 17.291 3.74754 17.3357 3.41161 17.3923C3.07569 17.4488 2.73554 17.4775 2.39117 17.4775C2.0468 17.4775 1.73872 17.4531 1.46525 17.405C1.19178 17.356 0.961365 17.2589 0.773144 17.1129C0.584923 16.9669 0.440591 16.7685 0.339306 16.517C0.237177 16.2663 0.187385 15.938 0.187385 15.5329L0.187385 5.63903C0.359569 5.60696 0.612773 5.56223 0.949545 5.50567C1.28547 5.44912 1.62563 5.42043 1.97 5.42043C2.31352 5.42043 2.62244 5.4449 2.89591 5.49301C3.16938 5.54197 3.39979 5.63903 3.58802 5.78505C3.77624 5.93107 3.91972 6.12942 4.02185 6.38094C4.12314 6.63247 4.17378 6.9608 4.17378 7.3651L4.17378 17.2589Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="1.1%"
              left="78.54%"
              right="16.22%"
              {...getOverrideProps(overrides, "LogoText_i")}
            ></Icon>
            <Icon
              width="11.09px"
              height="16.29px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 11.09322452545166,
                height: 16.286598205566406,
              }}
              paths={[
                {
                  d: "M11.0932 13.9283C11.0147 14.6905 10.8003 15.2738 10.4484 15.6789C10.0964 16.084 9.58408 16.2866 8.91223 16.2866C8.41171 16.2866 7.96605 16.1608 7.57526 15.9102C7.18447 15.6595 6.76245 15.2341 6.3092 14.634L3.98724 11.6199L3.98724 16.0444C3.81506 16.0764 3.56101 16.1212 3.22508 16.1777C2.88831 16.2343 2.549 16.263 2.20463 16.263C1.86026 16.263 1.55135 16.2385 1.27872 16.1904C1.00525 16.1414 0.774835 16.0444 0.586614 15.8983C0.398393 15.7523 0.254061 15.554 0.152776 15.3024C0.0506474 15.0518 0 14.7234 0 14.3183L0 0.243083C0.172184 0.194129 0.425399 0.141799 0.762171 0.0852482C1.0981 0.0286975 1.43824 0 1.78261 0C2.12614 0 2.43506 0.0244772 2.70853 0.0725875C2.982 0.121542 3.21242 0.218606 3.40064 0.364625C3.58886 0.510644 3.73235 0.708993 3.83448 0.960517C3.93576 1.21204 3.98724 1.54037 3.98724 1.94467L3.98724 8.36191L8.02092 4.08347C8.83373 4.08347 9.48702 4.27 9.9791 4.64222C10.4712 5.01529 10.7176 5.48542 10.7176 6.05177C10.7176 6.29485 10.6788 6.51768 10.6003 6.72025C10.5218 6.92282 10.4011 7.12961 10.2365 7.33977C10.0719 7.55078 9.86092 7.77361 9.60349 8.00825C9.34521 8.24374 9.04474 8.51468 8.70037 8.82275L7.34062 10.0382L11.0932 13.9283Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="6.88%"
              bottom="0.96%"
              left="86.66%"
              right="0%"
              {...getOverrideProps(overrides, "LogoText_k")}
            ></Icon>
          </View>
          <View
            padding="0px 0px 0px 0px"
            width="18.87px"
            height="22.83px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0.49%"
            bottom="0%"
            left="0%"
            right="82.63%"
            {...getOverrideProps(overrides, "Logo")}
          >
            <Icon
              width="18.87px"
              height="13.92px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 18.870075225830078,
                height: 13.922236442565918,
              }}
              paths={[
                {
                  d: "M18.8645 1.72996C18.8645 2.13004 18.8493 2.53096 18.8687 2.93019C18.8873 3.29988 18.7134 3.51511 18.407 3.69067C17.6212 4.14054 16.8219 4.57438 16.0733 5.0808C14.4755 6.16202 13.152 7.52852 12.0236 9.09421C11.063 10.4261 10.3642 11.8804 9.86197 13.4368C9.83918 13.5069 9.81893 13.5778 9.78854 13.6444C9.71258 13.8124 9.59779 13.9263 9.39691 13.9221C9.18843 13.9179 9.12006 13.7668 9.07533 13.6065C8.82971 12.7236 8.51657 11.866 8.0878 11.0549C6.64365 8.32107 4.62217 6.12657 1.96682 4.52796C1.49838 4.24605 1.02318 3.97511 0.540394 3.72105C0.170704 3.52608 -0.0158287 3.26865 0.00105212 2.82721C0.0297495 2.06842 0.0069602 1.30879 0.00611616 0.549151C0.00611616 -0.0146682 0.196869 -0.128614 0.697385 0.144011C2.48169 1.11719 4.26514 2.09206 6.0486 3.06692C7.00827 3.59192 7.96963 4.11353 8.92424 4.64697C9.29815 4.85544 9.64505 4.83266 10.0122 4.63009C12.7224 3.1412 15.4352 1.65822 18.1479 0.174397C18.721 -0.138742 18.8662 -0.0501179 18.8653 0.603169C18.8645 0.978767 18.8653 1.35436 18.8653 1.72996L18.8645 1.72996Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.02%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "LogoChevron")}
            ></Icon>
            <Icon
              width="7.15px"
              height="7.17px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 7.149808883666992,
                height: 7.16815185546875,
              }}
              paths={[
                {
                  d: "M7.1489 1.76947C7.1489 1.32889 7.15143 0.887451 7.14805 0.446862C7.14468 0.0594475 6.88471 -0.111049 6.54372 0.0763284C5.38148 0.714423 4.22599 1.36433 3.06881 2.01171C2.15049 2.52067 1.23386 3.03385 0.317236 3.54618C0.10707 3.6635 0.00409808 3.84834 0.00409808 4.08974C0.00325404 4.96332 -0.00518682 5.8369 0.00494166 6.71048C0.0100059 7.13082 0.279255 7.2819 0.647256 7.07849C1.61115 6.54505 2.57167 6.0074 3.53219 5.46975C4.62522 4.86373 5.71487 4.25264 6.80537 3.64324C7.03917 3.51242 7.15565 3.3242 7.14552 3.04397C7.13033 2.62027 7.1413 2.19487 7.1413 1.77032L7.1489 1.77032L7.1489 1.76947Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="19.68%"
              bottom="48.92%"
              left="46.23%"
              right="15.88%"
              {...getOverrideProps(overrides, "LogoParallelogram")}
            ></Icon>
            <Icon
              width="10.86px"
              height="7.34px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.858887672424316,
                height: 7.343537330627441,
              }}
              paths={[
                {
                  d: "M10.8528 1.79591L10.8519 1.79591C10.8519 1.33928 10.8561 0.881813 10.8502 0.425188C10.8452 0.0445255 10.5903 -0.105714 10.2619 0.0782871C9.09632 0.731574 7.93323 1.39077 6.76593 2.04237C6.76255 2.04406 6.76086 2.04574 6.75749 2.04743C6.7406 2.05419 6.72288 2.06094 6.70516 2.06938C6.66042 2.08963 6.61822 2.11411 6.57517 2.13775C5.81976 2.55808 5.05759 2.96828 4.31061 3.4038C3.85736 3.66799 3.44716 3.68571 2.98294 3.40549C2.21486 2.94212 1.42315 2.51672 0.632287 2.09217C0.235588 1.87947 0.00432067 2.03477 0.00432067 2.48465C0.00347663 3.33375 0.0119168 4.1837 0.000100216 5.0328C-0.00411998 5.33413 0.125019 5.52825 0.374011 5.66499C1.3109 6.17985 2.24778 6.69387 3.18382 7.21043C3.49527 7.38261 3.80588 7.39021 4.11733 7.21887C5.06941 6.69725 6.01979 6.1731 6.97018 5.65064C7.00057 5.63376 7.02758 5.61603 7.0529 5.59662C7.08666 5.58227 7.12127 5.56624 7.15756 5.54682C7.38714 5.42359 7.60997 5.28602 7.83701 5.15857C8.69793 4.67746 9.55886 4.19467 10.4223 3.71779C10.7228 3.55236 10.8781 3.327 10.857 2.96828C10.8359 2.58002 10.8528 2.18754 10.8528 1.79591Z",
                  fill: "rgba(174,179,183,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="67.83%"
              left="12.25%"
              right="30.2%"
              {...getOverrideProps(overrides, "LogoCheckmark")}
            ></Icon>
          </View>
        </View>
      </View>
    </View>
  );
}
