import React from 'react';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';

import {Card, Divider, Flex} from '@aws-amplify/ui-react';

import { useSession } from './SessionContext';

import { Text, View } from '@aws-amplify/ui-react';
import {Link} from "react-router-dom";

function Consulting() {
    const authState = useSession();
    const query = new URLSearchParams(window.location.search);

    const { userAttributes } = useSession();

    return (
      <View style={{ overflowY: "auto", width: "100%", height: "100%" }} backgroundColor="font.secondary">

          <Flex direction="column" justifyContent="space-around" alignItems="center">
            <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.active" margin="xl medium xs medium">Consulting Services</Text>
          </Flex>
          <Card variation="elevated" backgroundColor="brand.primary.60" margin="xs xl"
                                    padding="large" borderRadius="xl">
              <Text as="p" color="font.primary" margin="small medium">
                  Your business is growing, and so are the ways we can team up!
              </Text>
              <Text as="p" color="font.primary" margin="small medium">
                  Need help on a project?
                  Thinking about a long-term advisor or a tech expert to keep things
                  running&nbsp;smoothly?
                  I've&nbsp;got flexible options&nbsp;for&nbsp;you.
              </Text>
              <Text as="p" color="font.primary" margin="small medium">
                  Let’s talk and find the perfect fit, or simply pick one of my ready-to-go hiring models below.
              </Text>
              <Text as="p" color="font.primary" margin="small medium">
                  Together, we’ll make sure you get exactly what you need from Day One.
              </Text>
              <Divider/>
              <stripe-pricing-table pricing-table-id="prctbl_1OucVZ4UYOtzVUuQWoK7sa29"
                                  publishable-key="pk_live_HOXiVOfeGztnUUmWW6C9TEvB">
              </stripe-pricing-table>
              <Divider/>
          </Card>
      </View>
    );
}

export default Consulting;
