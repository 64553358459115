import { Helmet } from "react-helmet";
import { Outlet  } from "react-router-dom";
import {Logo, LogoWithText} from './ui-components';
import Session from './Session';
import NavMenu from "./NavMenu";
import './Layout.css';
import {Card, useTheme, View} from "@aws-amplify/ui-react";
import BottomSheet from "./BottomSheet";
import React from "react";

function MapLayout() {
    const { tokens } = useTheme();
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
            <div className="MainContainer">
                <div className="LeftBar column" style={{backgroundColor: tokens.colors.brand.primary[80]}}>
                    <LogoWithText color="brand" margin="xl xs 0 xxxs"/>
                    <View backgroundColor="font.secondary">
                      <Card height="72px" backgroundColor="brand.primary.80" borderRadius="0 0 72px 0"></Card>
                    </View>
                    <Card backgroundColor="font.secondary" borderRadius="80px 0 0 48px">
                        <NavMenu/>
                    </Card>
                    <View backgroundColor="font.secondary">
                        <Card height="112px" backgroundColor="brand.primary.80" borderRadius="0 112px 0 0"></Card>
                    </View>
                </div>
                <View className="Main" backgroundColor="font.secondary">
                    <div className="Banner">
                        <View>
                            <Logo className="TopBar no-print" color="brand"></Logo>
                        </View>
                        <View>
                            <div className="TopBar row"><NavMenu/></div>
                        </View>
                        <Session/>
                    </div>
                    <Outlet/>
                    <BottomSheet>
                    </BottomSheet>
                </View>
            </div>
      </>
    );
}

export default MapLayout;
