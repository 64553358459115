import {Divider, Text, useTheme} from "@aws-amplify/ui-react";
import {Link} from "react-router-dom";
import React from "react";

function LegalMenu() {
    const { tokens } = useTheme();

    return (
      <nav>
        <ul className="menu no-print" style={{ marginRight: tokens.space.xxxs.value }}>
          <li style={{listStyleType: "'🏠'"}}>
            <Link to="/">
              <Text fontSize="medium" className="App-link">Start</Text>
            </Link>
          </li>
          <li style={{listStyleType: "'⚖️'"}}>
            <Link to="/terms/">
              <Text fontSize="medium" className="App-link">Terms<span className="blaBla"> of Service</span></Text>
            </Link>
          </li>
          <li>
            <Link to="/privacy/">
              <Text fontSize="medium" className="App-link">Privacy<span className="blaBla"> Policy</span></Text>
            </Link>
          </li>
          <li>
            <Link to="/abuse/">
              <Text fontSize="medium" className="App-link">Restrictions<span className="blaBla"> on Use</span></Text>
            </Link>
          </li>
        </ul>
      </nav>
    );
}

export default LegalMenu;