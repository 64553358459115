import {generateClient} from "aws-amplify/api";
import {getAccount, getFederatedId, listAccounts,
//    accountStatusesByAccountId
} from "../graphql/queries";
import {getSocialIdentities, listFederatedIdsByUsername} from "./federatedIds";

const client = generateClient({authMode: 'userPool'});

const accountStatusesByAccountId = /* GraphQL */ `
    query AccountStatusesByAccountId(
        $accountId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAccountStatusFilterInput
        $limit: Int
        $nextToken: String
    ) {
        accountStatusesByAccountId(
            accountId: $accountId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                accountId
                lastSignInAt
                licenseStatus
                redactedAt
                signInAttemptAt
                signInAttempts
                verificationAttemptAt
                verificationAttempts
                verifiedAt
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
export async function findAccountStatusesByAccountId(accountId) {
    console.log(`Loading account status records for account ID ${accountId}`);
    const variables = { accountId, limit: 20 };
    const result = await client.graphql({
        query: accountStatusesByAccountId,
        variables: variables,
    });
    const accountStatuses = result.data.accountStatusesByAccountId.items;
    return accountStatuses.filter(Boolean);
}

/**
 * Returns any linked accounts for a given set of Federated IDs.
 * Each account is returned only once, even if it has multiple Personal IDs.
 * @param federatedIds
 * @returns {Promise<Awaited<*|string>[]>}
 */
async function getLinkedAccounts(federatedIds) {
    if (federatedIds === null) {
        return null;
    }
    const accountIds = [ ...new Set(federatedIds.map((p) => p.accountId)) ];
    const accounts = await Promise.all(accountIds.map(findAccount));
    return accounts.filter(Boolean);
}

async function findAccount(accountId) {
     const result = await client.graphql({
         query: getAccount,
         variables: { id: accountId }
     });
     return result.data.getAccount;
}

/**
 * Resolves potentially different sets of account returned by social identity and authenticating details.
 * @param linkedAccounts accounts found by social identity
 * @param authenticAccounts accounts found by authenticating details
 * @returns {*[]}
 */
function matchingAccounts(authentic, linkedAccounts, authenticAccounts) {
    if (authenticAccounts && authenticAccounts.length > 0) {
        // Found at least one existing account matching authenticating details.
        if (authenticAccounts.length === 1) {
            return authenticAccounts;
        }
        console.warn(`Found ${authenticAccounts.length} accounts for authenticating details`);
    }
    else { // Did not find an existing account searching by authenticating details.
        if (linkedAccounts && linkedAccounts.length > 0) {
            const linked = linkedAccounts.find((linked) =>
                linked.sub === authentic.sub
                || linked.email === authentic.email
                || linked.phoneNumber === authentic.phoneNumber
            );
            if (linked) {
                // Found an existing account linked by social identity.
                // We don't need to create a new account.
                // We only return a linked account with a matching detail.
                console.warn(`Found linked account ID ${linked.id} for sub ${authentic.sub}, but some authentication details mismatch`);
                return [ linked ];
            }
            // Found at least one linked account, but without any matching authenticated detail.
            // Let's go with the first one.
            console.warn(`Found ${linkedAccounts.length} linked account(s), but none match authenticating details`);
            return linkedAccounts;
        }
        else {
            // Did not find any existing account by any means.
            return null;
        }
    }
    // Found multiple accounts by authenticating details.
    // Attempt to resolve the correct account by matching linked social identity.

    const authenticIds = authenticAccounts?.map(a => a.id) || [];
    const linkedIdSet = new Set(linkedAccounts?.map(a => a.id) || []);
    const intersectionSet = new Set(authenticIds.filter(id => linkedIdSet.has(id)));

    if (intersectionSet.size === 0) {
        console.warn(`Found ${authenticAccounts?.length || 0} accounts for authenticating details, but none of the ${linkedAccounts?.length || 0} linked accounts match`);
        return authenticAccounts || [];
    }
    console.log.info(`Found ${intersectionSet.size} accounts with matching authenticating details and linked accounts`);
    if (intersectionSet.size === authenticIds.length) {
        return authenticAccounts || [];
    }
    else {
        console.warn.info(`Authenticating details match ${authenticAccounts?.length || 0} accounts,`
        + ` social identities match ${linkedAccounts?.length || 0} accounts,`
        + ` and ${intersectionSet.size} accounts match both`);
        return authenticAccounts?.filter(account => intersectionSet.has(account.id)) || [];
    }
}

export async function existingAccounts(user, attributes) {
    if (!attributes) {
        return {
            authentic: null, primary: null, authenticAccounts: null, linkedAccounts: null, matchedAccounts: null
        };
    }
    // Resolve previously linked social identities
    const { primary, identities } = getSocialIdentities(attributes);
    const federatedIds = await listFederatedIdsByUsername(user.username);
    const linkedAccounts = await getLinkedAccounts(federatedIds);
    if (linkedAccounts) {
        console.log(`Linked account${linkedAccounts.length > 0? 's' : ''}: ${linkedAccounts.map(a => a.id).join()}`);
    }

    // Resolve authenticated/verified identifying details
    // We only use verified details for matching existing accounts.
    // We do not trust social providers (e.g. Apple, Google, Facebook)
    // to provide verified details; they are only linked for convenience.
    // We ignore the username, because it's a derived field.
    const authentic= {
        sub: attributes.sub,
        email: Boolean(attributes.email_verified)? attributes.email : null,
        phoneNumber: Boolean(attributes.phone_number_verified)? attributes.phone_number : null,
        username: user.username
    }

    const authVariables = {
        filter: {
            or: {
                ...(authentic.sub && { sub: { eq: authentic.sub } }),
                ...(authentic.email && { email: { eq: authentic.email } }),
                ...(authentic.username && { username: { eq: authentic.username } }),
            }
        }, limit: 20 // Should be 1, but we want to see if there are multiple accounts
    };

    const result = await client.graphql({
        query: listAccounts,
        variables: authVariables
    });
    const authenticAccounts = result.data.listAccounts.items.filter(Boolean);
    const matchedAccounts = matchingAccounts(authentic, linkedAccounts, authenticAccounts);
    return { authentic, primary, authenticAccounts, linkedAccounts, matchedAccounts };
}
