import React, {useEffect, useState} from 'react';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';

import {Card, Flex, Loader, useAuthenticator} from '@aws-amplify/ui-react';

import { Text, View } from '@aws-amplify/ui-react';
import CustomAuthenticator from "./CustomAuthenticator";
import {useSession} from "./SessionContext";
import SignInLoader from "./SignInLoader";

function SignIn() {
    const query = new URLSearchParams(window.location.search);
    // OAuth 2.0 response parameters
    const { error, error_description, state } = Object.fromEntries(query);

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        // Set up a timer that ticks every second
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);

        // Clean up the interval on unmount
        return () => clearInterval(interval);
    });

    return (<>
      {state?(
          <View className="fill-available" backgroundColor="font.secondary">
            <Flex direction="column" justifyContent="space-around" alignItems="center">
                {error ? <>
                    <Card backgroundColor="background.error" borderRadius="xl" margin="xs xl">
                        <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.error" margin="small medium">
                            {error}
                        </Text>
                        <Text as="p" color="font.error" margin="small medium">
                            {error_description}
                        </Text>
                    </Card>
                </> :
                    <SignInLoader/>
                }
            </Flex>
          </View>
        ):( <CustomAuthenticator/>
      )}
    </>);
}

export default SignIn;
