import React from 'react';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';

import {Card, Flex} from '@aws-amplify/ui-react';

import { useSession } from './SessionContext';
import {Link} from "react-router-dom";

import { Text, View } from '@aws-amplify/ui-react';

function Start() {
    const authState = useSession();
    const query = new URLSearchParams(window.location.search);
    // OAuth 2.0 response parameters
    const { error, error_description } = Object.fromEntries(query);

    const { userAttributes } = useSession();

    return (
      <View className="fill-available" backgroundColor="font.secondary">
        <Flex direction="column" justifyContent="space-around" alignItems="center">
            {error ?
                <Card backgroundColor="background.error" borderRadius="xl" margin="xs xl">
                    <Text as="h2" color="font.error" margin="small medium">
                        {error}
                    </Text>
                    <Text as="p" color="font.error" margin="small medium">
                        {error_description}
                    </Text>
                </Card>
                : ''
            }
            <Text as="h1" fontSize="xxl" fontWeight="bold" color="font.active" margin="xl medium xs medium">
              Welcome to TodoTik
            </Text>
            <Card variation="elevated" backgroundColor="brand.primary.90" maxWidth="300pt" margin="xs xl"
                  padding="large" borderRadius="xl">
                <Text as="p" color="font.primary" margin="small medium">
                    TodoTik is a simple to-do list app that uses your location
                    to remind you of tasks when you are in the right&nbsp;place.
                </Text>
                {authState === 'authenticated'? (
                    ''
                ) : (<>
                    <Text as="p" color="font.primary" margin="small medium">
                        Before getting started, make sure to <Link className="App-link" to="/signin/">sign&nbsp;in
                        or register for a&nbsp;TodoTik&nbsp;account</Link>.
                    </Text>
                </>)}
                <Text as="p" color="font.primary" margin="small medium">
                    We thoroughly check everyone's details to make sure you can
                    connect with others on the app safely and with confidence.
                </Text>

            </Card>
        </Flex>
      </View>
    );
}

export default Start;
